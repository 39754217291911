import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const d = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
  [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
  [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
  [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
  [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
  [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
  [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
  [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
  [9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
];

const p = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
  [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
  [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
  [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
  [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
  [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
  [7, 0, 4, 6, 9, 1, 3, 2, 5, 8],
];

const inv = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9];

// Function to validate Aadhaar number using Verhoeff algorithm
const validateVerhoeff = (num) => {
  let c = 0;
  const myArray = stringToReversedIntArray(num);

  myArray.forEach((digit, i) => {
    c = d[c][p[i % 8][digit]];
  });

  return c === 0;
};

// Helper function to convert a string into a reversed array of integers
const stringToReversedIntArray = (num) => {
  const myArray = Array.from(num).map((char) => parseInt(char, 10));
  return myArray.reverse();
};
export default function Addsmalladmins_customer() {
  const broker_id = localStorage.getItem("broker_id");
  const clearcompany_id = localStorage.getItem("clearcompany_id");
  const company_id = localStorage.getItem("company_id");
  const company_Name = localStorage.getItem("company_Name");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [dataplan, setDataplan] = useState([]);
  const [newImageadhar, setNewImageadar] = useState(null);
  const [adharimg, setAdharImg] = useState("");

  const [newImageadharback, setNewImageadarBack] = useState(null);
  const [adharbackimg, setAdharBackImg] = useState("");

  const [newImagepan, setNewImagepan] = useState(null);
  const [panimg, setPanImg] = useState("");

  const [transactionAmount, setTransactionAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [fileimagetr, setFileimagetr] = useState("");
  const [BaseQrUrl, setBaseQrUrl] = useState("");
  const [BaseQrImage, setBaseQrImage] = useState("");

  const [newImagetr, setNewImagetr] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [type, setType] = useState("online");
  const [paymentMode, setPaymentMode] = useState("");
  const [billId, setBillId] = useState("");
  const [utrNo, setUtrNo] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOnline, setIsOnline] = useState(true);

  const [formData, setFormData] = useState({
    first_name: "",
    aadharnumber: "",
    pannumber: "",
    invested_amount: "",
    email: "",
    address: "",
    cust_ac_no: "",
    acc_holder_name: "",
    bank_branch: "",
    bank_address: "",
    ifsc_code: "",
    phone: "",
    altername_phone: "",
    profile_image: null,
    plan_id: "",
    marital_status: "",
    password: "",
    gender: "",
    joining_date: new Date().toISOString().split("T")[0],
    profile_image: null,
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImageProfile();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setFileimage(URL.createObjectURL(file));
        setNewImage(file);
      }
    }
  };
  const handleClearImageProfile = () => {
    setFileimage("");
    setNewImage(null);
    // Reset the file input field
    const fileInput = document.getElementById("profile_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };
  const handleAdharImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImage();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setAdharImg(URL.createObjectURL(file));
        setNewImageadar(file);
      }
    }
  };

  const handleJoiningDateChange = (date) => {
    // Update the formData state with the selected date
    setFormData({
      ...formData,
      joining_date: date ? date.toISOString().split("T")[0] : ""
    });
    console.warn("Selected date:", date);
  };

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const handleClearImage = () => {
    setNewImageadar(null);
    setAdharImg("");
    const fileInput = document.getElementById("uan_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };
  const handleAdharBackImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImageback();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setAdharBackImg(URL.createObjectURL(file));
        setNewImageadarBack(file);
      }
    }
  };
  const handleClearImageback = () => {
    setNewImageadarBack(null);
    setAdharBackImg("");

    const fileInput = document.getElementById("back_uan_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };
  const handlePanImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImagePan();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setPanImg(URL.createObjectURL(file));
        setNewImagepan(file);
      }
    }
  };
  const handleClearImagePan = () => {
    setNewImagepan(null);
    setPanImg("");

    const fileInput = document.getElementById("pan_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };

  const handleFileChangetr = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImageTr();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setFileimagetr(URL.createObjectURL(file));
        setNewImagetr(file);
      }
    }
    setErrors((prevErrors) => ({ ...prevErrors, trnx_image: null }));
  };

  const handleClearImageTr = () => {
    setNewImagetr(null);
    setFileimagetr("");

    const fileInput = document.getElementById("trnx_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };
  const handleInputChangetr = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === "transaction_amount") {
      // Allow only numeric input, remove leading zeros, and restrict to 12 digits
      if (/^[0-9]*$/.test(value) && value.length <= 12) {
        formattedValue = value.replace(/^0+/, ""); // Remove leading zeros
      } else {
        return; // Do not update state for invalid input
      }
    }else if (name === "transaction_id") {
      formattedValue = value
       
        .replace(/[^a-zA-Z0-9]/g, "")
        .slice(0, 20);
    }
    // else if (name === "transaction_id") {
    //   formattedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 15);
    // }
    // else if (name === "utr_no") {
    //   formattedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 15);
    // }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));

    if (name === "transaction_amount") {
      setTransactionAmount(formattedValue);
    } else if (name === "transaction_id") {
      setTransactionId(formattedValue);
    }
    // else if (name === "bill_id") {
    //   setBillId(formattedValue);
    // }
    // else if (name === "utr_no") {
    //   setUtrNo(formattedValue);
    // }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    // Validation for first name
    if (name === "first_name") {
      if (value.length > 30 || !/^[a-zA-Z\s]*$/.test(value)) {
        return; // Reject invalid input
      }
    }

    // Validation for phone number
    if (name === "phone" && (value.length > 10 || isNaN(value))) {
      return; // Reject invalid input
    }

    // Validation for customer account number
    if (name === "cust_ac_no" && (value.length > 17 || isNaN(value))) {
      return; // Reject invalid input
    }

    // Validation for alternate phone number
    if (name === "altername_phone" && (value.length > 10 || isNaN(value))) {
      return; // Reject invalid input
    }

    // Formatting and validation for Aadhaar number
    if (name === "aadharnumber") {
      formattedValue = value.replace(/\D/g, "").slice(0, 12); // Allow only digits, max 12
    }
    // Formatting and validation for Aadhaar number
    if (name === "ifsc_code") {
      formattedValue = value.replace(/[^A-Za-z0-9]/g, "").toUpperCase().slice(0, 11);
    }

    // Formatting and validation for PAN number
    if (name === "pannumber") {
      formattedValue = value
        .toUpperCase() // Convert to uppercase
        .replace(/[^A-Z0-9]/g, "") // Remove invalid characters
        .slice(0, 10); // Limit to 10 characters

      // Validate PAN format
      // const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
      // if (!panRegex.test(formattedValue)) {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     [name]: "Invalid PAN number format.",
      //   }));
      //   return; // Prevent updating state if invalid
      // }
    }

    // Update the form data state with valid input
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));

    // Clear any previous errors for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null,
    }));
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "first_name") {
  //     if (value.length > 30 || !/^[a-zA-Z\s]*$/.test(value)) {
  //     return
  //     }
  //   }
  //   if (name === "phone" && (value.length > 10 || isNaN(value))) {
  //     return;
  //   }
  //   if (name === "cust_ac_no" && ( value.length > 17 || isNaN(value))) {
  //     return;
  // }

  //   if (name === "altername_phone" && (value.length > 10 || isNaN(value))) {
  //     return;
  //   }

  //   let formattedValue = value;

  //   if (name === "aadharnumber") {
  //     formattedValue = value.replace(/\D/g, "").slice(0, 12);
  //   }
  //   if (name === "pannumber") {
  //     formattedValue = value
  //       .toUpperCase()
  //       .replace(/[^A-Z0-9]/g, "")
  //       .slice(0, 10);

  //     // Validate PAN format
  //     const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  //     if (!panRegex.test(formattedValue)) {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: "Invalid PAN number format.",
  //       }));
  //       return; // Prevent updating the state if invalid
  //     }
  //   }
  //   // if
  //   //  (name === "password") {
  //   //   formattedValue = value.slice(0, 12);
  //   // }
  //   // else if (name === "password") {
  //   // }

  //   setFormData({ ...formData, [name]: formattedValue });

  //   setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  // };
  // Payment Mode Handler
  const handlePaymentModeChange = (isOnlineMode) => {
    setType(isOnlineMode ? "online" : "offline");
    setIsOnline(isOnlineMode);
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, payment_type: null }));
  };
  const handlePaymentModeChange1 = (e) => {
    setPaymentMode(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, payment_mode: null }));
  };

  const handleValidation = () => {
    let validationErrors = {};
    let hasError = false;

    if (!transactionAmount) {
      validationErrors.transaction_amount = "Transaction amount is required";
      hasError = true;
    } else if (
      isNaN(transactionAmount) ||
      transactionAmount <= 0 ||
      transactionAmount.length > 12 
    ) {
      validationErrors.transaction_amount =
        "Transaction amount must be a positive number and not exceed 12 digits";
      hasError = true;
    }

    if (isOnline && !transactionId) {
      validationErrors.transaction_id =
        "Transaction ID is required for online mode";
      hasError = true;
    } else if (isOnline && !/^[a-zA-Z0-9]+$/.test(transactionId)) {
      validationErrors.transaction_id = "Transaction ID must be alphanumeric";
      hasError = true;
    }

    // if (!isOnline && !billId) {
    //   validationErrors.bill_id = "Bill ID is required for offline mode";
    //   hasError = true;
    // }

    if (!newImagetr) {
      validationErrors.trnx_image = "Transaction image is required";
      hasError = true;
    }

    if (!paymentType) {
      validationErrors.payment_type = "Payment type is required";
      hasError = true;
    }

    if (!paymentMode) {
      validationErrors.payment_mode = "Payment mode is required";
      hasError = true;
    }
    if (!type) {
      validationErrors.type = "Payment type is required";
      hasError = true;
    }

    setErrors(validationErrors);
    return !hasError;
  };
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-company-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const companyData = response.data.data;
      setData(companyData[0].id);
      console.warn("response.data.data", companyData[0].id);
      // if (companyData.length === 0) {
      //   Swal.fire({
      //     icon: 'warning',
      //     title: 'No Companies Found',
      //     text: 'Please add a company.',
      //   });
      // } else {
      //   setData(companyData);
      //   console.warn("response.data.data", companyData);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataine_plan = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-investment-active-plans-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setDataplan(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  
  useEffect(() => {
    fetchData();
    fetchDataine_plan();

    if (clearcompany_id === "1") {
      localStorage.removeItem("company_id");
    }
  }, [clearcompany_id]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const requiredFields = [
      { field: "first_name", name: "Full Name" },
      { field: "pannumber", name: "pannumber" },
      { field: "aadharnumber", name: "Aadhar Number" },
      { field: "email", name: "Email" },
      { field: "phone", name: "Phone" },
      { field: "address", name: "address" },
      { field: "cust_ac_no", name: "cust_ac_no" },
      { field: "acc_holder_name", name: "acc_holder_name" },
      { field: "bank_branch", name: "bank_branch" },
      { field: "bank_address", name: "bank_address" },
      { field: "ifsc_code", name: "ifsc_code" },
      // { field: "password", name: "password" },
    ];

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = true;
        hasError = true;
      }
    }

    if (formData.phone.length !== 10) {
      validationErrors.phone = true;
      hasError = true;
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }

    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    if (!panRegex.test(formData.pannumber)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid PAN Number",
        html: `
    <div class="swal-custom-text text-start">
      PAN numbers must be:
      <ul style="list-style:auto">
        <li>Contain the first 5 characters as uppercase alphabets.</li>
        <li>Contain the next 4 characters as digits.</li>
        <li>End with 1 uppercase alphabet.</li>
      </ul>
      Please enter a valid PAN number.
    </div>
  `, // Use `html` for custom content
        confirmButtonText: "OK", // Button text for confirmation
        customClass: {
          htmlContainer: "swal-custom-html", // Apply class to the text container
        }, // Adds an OK button
      });
      setLoading(false);
      return;
    }
    
  // Aadhaar validation
  if (!validateVerhoeff(formData.aadharnumber)) {
    Swal.fire({
      icon: "warning",
      title: "Invalid Aadhaar Number",
      text: "The Aadhaar number entered is invalid. Please check and try again.",
      confirmButtonText: "OK",
    });
    setLoading(false);
    return;
  }
    
  if (formData.cust_ac_no.length < 8 || formData.cust_ac_no.length > 17) {
    Swal.fire({
      icon: 'warning',
      title: 'Account Number Issue',
      text: `Account numbers must be between 8 and 17 characters in length. Please choose correct account number length`,
      confirmButtonText: 'OK', // Adds an OK button
    });
    setLoading(false);
    return;
  }
  

    if (!handleValidation()) {
      setLoading(false);
      return;
    }
 // Show confirmation dialog before proceeding
 const confirmResult = await Swal.fire({
  title: 'Are you sure?',
  text: "Do you want to add this customer?",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, Add Customer!',
  cancelButtonText: 'Cancel',
});

if (!confirmResult.isConfirmed) {
  setLoading(false);
  Swal.fire({
    icon: 'info',
    title: 'Cancelled',
    text: 'The customer addition was cancelled.',
  });
  return;
}
    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("user_id", broker_id);
      updatedFormData.append("company_id", 1);
      updatedFormData.append("status", "1");

      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== "" && value !== "null") {
          if (key !== "company_name" && key !== "company_id") {
            updatedFormData.append(key, value);
          }
        }
      });
      if (newImage) {
        updatedFormData.append("profile_image", newImage);
      }
      if (newImagepan) {
        updatedFormData.append("pan_image", newImagepan);
      }

      if (newImageadhar) {
        updatedFormData.append("uan_image", newImageadhar);
      }
      if (newImageadharback) {
        updatedFormData.append("back_uan_image", newImageadharback);
      }
      updatedFormData.append("transaction_amount", transactionAmount);

      if (isOnline) {
        updatedFormData.append("transaction_id", transactionId);
        updatedFormData.append("type", "online");
        updatedFormData.append("payment_mode", "online");
      } else {
        updatedFormData.append("transaction_id", transactionId);
        updatedFormData.append("type", "offline");
        updatedFormData.append("payment_mode", "offline");
      }

      updatedFormData.append("trnx_image", newImagetr);
      updatedFormData.append("payment_type", paymentType);
      updatedFormData.append("payment_mode_type", paymentMode);
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}add-swan-selfcompany-customer`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);

      if (response.data.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          // timer: 1500
        });
        setTimeout(() => {
          navigate("/Customermanagement");
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
          // timer: 2000
        });
      }
    } catch (error) {
      console.warn("Error add-swan-selfcompany-customer:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.email
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.error.email[0],
          // timer: 2000
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add customer",
          // timer: 2000
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Customer</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Customermanagement" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <form className="card-body" onSubmit={handleSubmit}>
          <h5>Customer Details</h5>
          <div className="row g-3 mt-2">
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="fullname">
                  Full Name <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.first_name ? "input-error" : ""
                  }`}
                  id="fullname"
                  name="first_name"
                  type="text"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="phone">
                  Mobile Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.phone ? "input-error" : ""
                  }`}
                  id="phone"
                  name="phone"
                  type="text"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="email">
                  Email <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.email ? "input-error" : ""
                  }`}
                  id="email"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="altername_phone">
                  Alternative Mobile Number{" "}
                  <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control ${
                    errors.altername_phone ? "input-error" : ""
                  }`}
                  id="altername_phone"
                  type="text"
                  name="altername_phone"
                  value={formData.altername_phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="address">
                  Address <span className="login-danger">*</span>
                </label>
                <textarea
                  className={`form-control ${
                    errors.address ? "input-error" : ""
                  }`}
                  id="address"
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms"></div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="pannumber">
                  Pan Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.pannumber ? "input-error" : ""
                  }`}
                  id="pannumber"
                   placeholder="Enter your PAN number (e.g., ABCDE1234F)"
                  type="text"
                  name="pannumber"
                  value={formData.pannumber}
                  onChange={handleInputChange}
                />
                 { errors.pannumber && (
      <div className="error-message">
        <strong>PAN numbers must be:</strong>
        <ol>
          <li>Exactly 10 characters long.</li>
          <li>Contain the first 5 characters as uppercase alphabets.</li>
          <li>Contain the next 4 characters as digits.</li>
          <li>End with 1 uppercase alphabet.</li>
        </ol>
      </div>
    )}
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="pan_image">
                  PAN Card Image <span className="login-danger"></span>
                </label>
                <input
                  // className="form-control"
                  className={`form-control ${
                    errors.pan_image ? "input-error" : ""
                  }`}
                  id="pan_image"
                  name="pan_image"
                  accept=".jpeg,.jpg,.png"
                  type="file"
                  value={formData.pan_image}
                  onChange={handlePanImageChange}
                />
                {panimg && (
                  <div className="mt-3 image_box">
                    <img
                      src={panimg}
                      alt="Selected profile"
                      style={{ height: "100px" }}
                    />
                    <ImCross onClick={handleClearImagePan} />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="aadharnumber">
                  Aadhaar Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.aadharnumber ? "input-error" : ""
                  }`}
                  id="aadharnumber"
                  type="text"
                  name="aadharnumber"
                  value={formData.aadharnumber}
                  onChange={handleInputChange}
                />
              </div>
              {errors.aadharnumber && 

<ol>
<li>  Aadhaar number must be exactly 12 digits and start with a number between 2 and 9.</li>
</ol> 
              }
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="uan_image">
                  Aadhaar Front Image <span className="login-danger"></span>
                </label>
                <input
                  // className="form-control"
                  className={`form-control ${
                    errors.uan_image ? "input-error" : ""
                  }`}
                  id="uan_image"
                  name="uan_image"
                  accept=".jpeg,.jpg,.png"
                  type="file"
                  value={formData.uan_image}
                  onChange={handleAdharImageChange}
                />
                {adharimg && (
                  <div className="mt-3 image_box">
                    <img
                      src={adharimg}
                      alt="Selected Aadhaar"
                      style={{ height: "100px" }}
                    />
                    <ImCross onClick={handleClearImage} />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms"></div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="back_uan_image">
                  Aadhaar Back Image <span className="login-danger"></span>
                </label>
                <input
                  // className="form-control"
                  className={`form-control ${
                    errors.back_uan_image ? "input-error" : ""
                  }`}
                  id="back_uan_image"
                  name="back_uan_image"
                  accept=".jpeg,.jpg,.png"
                  type="file"
                  value={formData.back_uan_image}
                  onChange={handleAdharBackImageChange}
                />
                {adharbackimg && (
                  <div className="mt-3 image_box">
                    <img
                      src={adharbackimg}
                      alt="Selected Aadhaar"
                      style={{ height: "100px" }}
                    />
                    <ImCross onClick={handleClearImageback} />
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="gender">
                  Gender <span className="login-danger"></span>
                </label>
                <select
                  className="form-select"
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  style={{
                    color: formData.gender === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                  }}
                >
                  <option value="" className="text-dark">
                    Select
                  </option>
                  <option value="Male" className="text-dark">
                    Male
                  </option>
                  <option value="Female" className="text-dark">
                    Female
                  </option>
                  <option value="Other" className="text-dark">
                    Other
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="marital_status">
                  Marital Status <span className="login-danger"></span>
                </label>
                <select
                  className="form-select"
                  id="marital_status"
                  name="marital_status"
                  value={formData.marital_status}
                  onChange={handleInputChange}
                  style={{
                    color:
                      formData.marital_status === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                  }}
                >
                  <option value="" className="text-dark">
                    Select
                  </option>
                  <option value="Single" className="text-dark">
                    Single
                  </option>
                  <option value="Married" className="text-dark">
                    Married
                  </option>
                  <option value="Other" className="text-dark">
                    Other
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="joiningDate">
                  Joining Date <span className="login-danger">*</span>
                </label>
                <DatePicker
                  className={`form-control ${errors.joining_date ? "input-error" : ""}`}
                  selected={formData.joining_date ? new Date(formData.joining_date) : null}
                  onChange={handleJoiningDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD-MM-YYYY"
                  maxDate={currentDate} // Restrict to current date or earlier
                  customInput={
                    <InputMask
                      mask="99/99/9999"
                      maskChar={null} // Remove placeholder character (for clarity)
                      value={formData.joining_date}
                      onChange={handleJoiningDateChange}
                    />
                  }
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="profile_image">
                  Profile Image <span className="login-danger"></span>
                </label>
                <input
                  className="form-control"
                  id="profile_image"
                  accept=".jpeg,.jpg,.png"
                  type="file"
                  name="profile_image"
                  value={formData.profile_image}
                  onChange={handleImageChange}
                />
                {fileimage && (
                  <>
                    <div className="mt-3 image_box">
                      <img
                        src={fileimage}
                        alt="Profile Preview"
                        style={{
                          width: "100px",
                          height: "100px",
                        }}
                      />
                      <ImCross onClick={handleClearImageProfile} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <h5>Bank Details</h5>
          <div className="row g-3 mt-2">
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="cust_ac_no">
                  Customer Account Number{" "}
                  <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.cust_ac_no ? "input-error" : ""
                  }`}
                  id="cust_ac_no"
                  name="cust_ac_no"
                  type="text"
                  value={formData.cust_ac_no}
                  onChange={handleInputChange}
                />
                { errors.cust_ac_no && 
                 <ol>
                 <li>Account numbers must be between 8 and 17 characters in length. Please choose correct account number length</li>
               </ol>
                }
               


              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="acc_holder_name">
                  Account Holder Name <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.acc_holder_name ? "input-error" : ""
                  }`}
                  id="acc_holder_name"
                  name="acc_holder_name"
                  type="text"
                  value={formData.acc_holder_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="ifsc_code">
                IFSC CODE <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.ifsc_code ? "input-error" : ""
                  }`}
                  id="ifsc_code"
                  name="ifsc_code"
                   placeholder="e.g., SBIN0001234"
                  type="text"
                  value={formData.ifsc_code}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="bank_branch">
                  Branch Name <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.bank_branch ? "input-error" : ""
                  }`}
                  id="bank_branch"
                  type="text"
                  name="bank_branch"
                  value={formData.bank_branch}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="bank_address">
                  Bank Adress <span className="login-danger">*</span>
                </label>
                <textarea
                  className={`form-control ${
                    errors.bank_address ? "input-error" : ""
                  }`}
                  id="bank_address"
                  type="text"
                  name="bank_address"
                  value={formData.bank_address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <h5>Customer Transaction Details</h5>
          <div className="card-body">
            <div className="col-md-12 mb-2">
              <div className="form-group local-forms d-flex align-items-center">
                <label className="w-25">
                  Payment Mode <span className="text-danger">*</span> :
                </label>
                <div className="d-flex w-75">
                  <button
                    type="button"
                    className={`btn ${
                      isOnline ? "btn-primary" : "btn-outline-primary"
                    }`}
                    onClick={() => handlePaymentModeChange(true)}
                  >
                    Online
                  </button>
                  <button
                    type="button"
                    className={`btn mx-1 ${
                      !isOnline ? "btn-primary" : "btn-outline-primary"
                    }`}
                    onClick={() => handlePaymentModeChange(false)}
                  >
                    Offline
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-2">
              <div className="form-group local-forms d-flex align-items-center">
                <label htmlFor="transaction_amount" className="w-25">
                  Transaction Amount <span className="text-danger">*</span> :{" "}
                </label>
                <input
                  type="text"
                  className={`form-control w-75 ${
                    errors.transaction_amount ? "is-invalid" : ""
                  }`}
                  id="transaction_amount"
                  name="transaction_amount"
                  value={transactionAmount}
                  maxLength={12}
                  onChange={handleInputChangetr}
                />
              </div>
            </div>
            {isOnline && (
              <>
                <div className="col-md-12 mb-2">
                  <div className="form-group local-forms d-flex align-items-center">
                    <label htmlFor="transaction_id" className="w-25">
                      Transaction ID <span className="text-danger">*</span> :{" "}
                    </label>
                    <input
                      type="text"
                      className={`form-control w-75 ${
                        errors.transaction_id ? "is-invalid" : ""
                      }`}
                      id="transaction_id"
                      name="transaction_id"
                      value={transactionId}
                      onChange={handleInputChangetr}
                    />
                  </div>
                </div>
              </>
            )}
            {!isOnline && (
              <div className="col-md-12 mb-2">
                <div className="form-group local-forms d-flex align-items-center">
                  <label htmlFor="transaction_id" className="w-25">
                    Bill ID <span className="text-danger">*</span> :{" "}
                  </label>
                  <input
                    type="text"
                    className={`form-control w-75 ${
                      errors.transaction_id ? "is-invalid" : ""
                    }`}
                    id="transaction_id"
                    name="transaction_id"
                    value={transactionId}
                    onChange={handleInputChangetr}
                  />
                </div>
              </div>
            )}
            <div className="col-md-12 mb-2">
              <div className="form-group local-forms d-flex align-items-center">
                <label htmlFor="payment_type" className="w-25">
                  {" "}
                  Payment Type <span className="text-danger">*</span> :{" "}
                </label>
                <select
                  className={`form-select w-75 ${
                    errors.payment_type ? "input-error" : ""
                  }`}
                  id="payment_type"
                  name="payment_type"
                  value={paymentType}
                  onChange={handlePaymentTypeChange}
                  style={{
                    color: paymentType === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                  }}
                >
                  <option value="" className="text-dark" disabled>
                    Select Payment Type
                  </option>
                  <option value="credit" className="text-dark">
                    Credit
                  </option>
                  {/* <option value="debit" className="text-dark">
    Debit
  </option> */}
                </select>
              </div>
            </div>
            <div className="col-md-12 mb-2">
              <div className="form-group local-forms d-flex align-items-center">
                <label className="w-25">
                  Payment Mode <span className="text-danger">*</span> :
                </label>
                <select
                  className={`form-select w-75 ${
                    errors.payment_mode ? "input-error" : ""
                  }`}
                  name="payment_mode"
                  value={paymentMode}
                  onChange={handlePaymentModeChange1}
                  style={{
                    color: paymentMode === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                  }}
                >
                  <option value="" className="text-dark" disabled>
                    Select Payment Mode
                  </option>
                  {!isOnline && (
                    <>
                      <option value="cash" className="text-dark">
                        Cash
                      </option>
                    </>
                  )}
                  {isOnline && (
                    <>
                      <option value="upi" className="text-dark">
                        UPI
                      </option>
                      <option value="neft" className="text-dark">
                        NEFT
                      </option>
                      <option value="rtgs" className="text-dark">
                        RTGS
                      </option>
                    </>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-12 mb-2">
              <div className="form-group local-forms d-flex align-items-center">
                <label htmlFor="trnx_image" className="w-25">
                  Transaction Image <span className="text-danger">*</span> :{" "}
                </label>
                <input
                  accept=".jpeg,.jpg,.png"
                  type="file"
                  className={`form-control w-75 ${
                    errors.trnx_image ? "is-invalid" : ""
                  }`}
                  id="trnx_image"
                  name="trnx_image"
                  onChange={handleFileChangetr}
                />
              </div>
            </div>
            {fileimagetr && (
              <div className="row mt-3">
                <p className="col-3"></p>
                <div className="col-9">
                  <div className="mt-3 image_box">
                    <img
                      src={fileimagetr}
                      alt="Selected transaction"
                      style={{ height: "150px", width: "150px" }}
                    />
                    <ImCross onClick={handleClearImageTr} />
                  </div>
                </div>
              </div>
            )}

            {errors.trnx_image && (
              <span className="error-message">{errors.pannumber}</span>
            )}
          </div>

          {/* <hr className="my-4 mx-n4" />

          <h5>Company Details</h5>

          {company_Name !== null ? (
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="company_id">
                  Company <span className="login-danger"></span>
                </label>
                <select
                  className={`form-select `}

                  id="company_id"
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleInputChange}
                  style={{
                    color: formData.company_id === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                  }}
                >
                    <option key={formData.company_id} value={formData.company_id} className="text-dark">
                      {company_Name}
                    </option>
                  
                </select>
              </div>
            </div>
              ):(
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="company_id">
                  Company <span className="login-danger"></span>
                </label>
                <select
                  className={`form-select `}
                  id="company_id"
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleInputChange}
                >
              <option value="">Select Company</option>
                  {data.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            )}
  
          <hr className="my-4 mx-n4" /> */}

          {/* <h5>Investment Details</h5>
          <div className="row g-3 mt-1">
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="plan_id">
                  Investment Plan <span className="login-danger"></span>
                </label>
                <select
                  className={`form-select`}
                  id="plan_id"
                  name="plan_id"
                  value={formData.plan_id}
                  onChange={handleInputChange}
                >
                  <option value="">Select Plan</option>
                  {dataplan.map((plan) => (
                    <option key={plan.id} value={plan.id}>
                      {plan.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="invested_amount">
                  Invested Amount <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="invested_amount"
                  type="text"
                  name="invested_amount"
                  value={formData.invested_amount}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div> */}

          <div className="pt-4 w-100 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
