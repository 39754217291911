import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { MdDashboard } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { TiContacts } from "react-icons/ti";
import { MdOutlineContactSupport } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { FaRegAddressBook } from "react-icons/fa6";
import { IoPersonOutline } from "react-icons/io5";
import { GrShieldSecurity } from "react-icons/gr";
import { MdNotificationsActive } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import axios from "axios";

import { FaHandHoldingHand } from "react-icons/fa6";
import { FaFileContract } from 'react-icons/fa';
import {
  MdOutlinePersonalInjury,
  MdOutlineManageAccounts,
  MdManageHistory,
  MdBusinessCenter,
  MdHistory,
} from "react-icons/md";
import { FaPaperPlane } from "react-icons/fa";

import { PiHandCoinsFill } from "react-icons/pi";
import { GrUserManager } from "react-icons/gr";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { TbArrowsTransferDown, TbReport } from "react-icons/tb";
// import Logo from "../assets/img/gallery/swaninvestment.png";
import Settings from './../pages/Home/Settings';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState("");

  const broker_id = localStorage.getItem("broker_id");
  const brokerId = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");

  const [formData1, setFormData1] = useState();

  const [BaseUrl, setBaseUrl] = useState("");


  const [baseurlS, setBaseurlS] = useState("");
  const [formData, setFormData] = useState({
    company_name: "",
    primary_number: "",
    secondary_number: "",
    wealth_short_desc: "",
    site_url: "",
    address: "",
    wealth_logo: null,
  })
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    // Add or remove class from the <html> tag based on the 'collapsed' state
    if (isSidebarOpen) {
      document.documentElement.classList.remove("navbar-vertical-collapsed");
    } else {
      document.documentElement.classList.add("navbar-vertical-collapsed");
    }
  }, [isSidebarOpen]);

  const handleClickHedgeCompanyId = () => {
    localStorage.setItem("clearcompany_id", "1");
  };

  const handleClickBusinessCompanyId = () => {
    localStorage.setItem("clearcompany_id", "2");
  };

  const handleMenuClick = (menuId) => {
    setActiveMenu(menuId);
  };



  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", brokerId);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}view-swan-selfcompany-general-settings`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const profileData = response.data.data;

      if (profileData) {
        setFormData({
          company_name: profileData.company_name || "",
          primary_number: profileData.primary_number || "",
          secondary_number: profileData.secondary_number || "",
          wealth_short_desc: profileData.company_short_desc || "",
          address: profileData.address || "",
          id: profileData.id || "",
          wealth_logo: profileData.company_logo || null,
          site_url: profileData.site_url || null,
        });
        setBaseUrl(response.data.SwanCompanyGeneralSettings_url);
        const logoUrl = `${response.data.SwanCompanyGeneralSettings_url}/${profileData.company_logo}`;
        setFavicon(logoUrl);
      } else {
        console.error("No profile data found.");
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const setFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.href = url;
      document.head.appendChild(newFavicon);
    }
  };
  
  return (
    <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
      <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
        <div className="d-flex align-items-center">
          <div className="toggle-icon-wrapper">
            <button
              className="btn navbar-toggler-humburger-icon toggle-btn navbar-vertical-toggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              onClick={toggleSidebar}
              title="Toggle Navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="toggle-line" />
              </span>
            </button>
          </div>
          <Link className="navbar-brand" to="/Home">
            <div className="d-flex align-items-center">
              {/* <img className="me-2" src={Logo} alt="" width={130} /> */}
              <img
                src={`${BaseUrl}/${formData.wealth_logo}`}
                alt=""
                width={35}
              />



            </div>
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
          <div className="navbar-vertical-content scrollbar">
            <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
              {/* Dashboard */}
              <li className={`nav-item new_menu ${activeMenu === "dashboard" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Home" onClick={() => handleMenuClick("dashboard")}>
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <MdDashboard />
                    </span>
                    <span className="nav-link-text ps-1">Dashboard</span>
                  </div>
                </Link>
              </li>
              {/* Company Management */}
              <li className={`nav-item new_menu ${activeMenu === "CompanyManagement" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Companymangement" onClick={() => handleMenuClick("CompanyManagement")}>
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <MdManageHistory />
                    </span>
                    <span className="nav-link-text ps-1">
                      {" "}
                      Company Management
                    </span>
                  </div>
                </Link>
              </li>
              {/*  Investment Plan */}
              <li className={`nav-item new_menu ${activeMenu === "InvestmentPlan" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Investment-management" onClick={() => handleMenuClick("InvestmentPlan")}>

                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <PiHandCoinsFill />
                    </span>
                    <span className="nav-link-text ps-1"> Plan Management</span>
                  </div>
                </Link>
              </li>
              <li className={`nav-item new_menu ${activeMenu === "customermanagement" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Customermanagement" onClick={() => handleMenuClick("customermanagement")}>

                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <FaUser />
                    </span>
                    <span className="nav-link-text ps-1">Customer Management</span>
                  </div>
                </Link>
              </li>
             
              

             
              <li className={`nav-item new_menu ${activeMenu === "Investmentinsawn" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Investmentinsawn" onClick={() => handleMenuClick("Investmentinsawn")}>
                  <div className="d-flex align-items-center">
                    <GiReceiveMoney />

                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      {/* Investment In  Swan */}
                      Investment In  company
                    </span>
                  </div>
                </Link>
              </li>
              <li className={`nav-item new_menu ${activeMenu === "PartnerTransition" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/PartnerTransaction" onClick={() => handleMenuClick("PartnerTransition")}>
                  <div className="d-flex align-items-center">
                    <FaHandHoldingHand />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Partner Transaction
                    </span>
                  </div>
                </Link>
              </li>
              <li className={`nav-item new_menu ${activeMenu === "AgreementList" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/AgreementList" onClick={() => handleMenuClick("AgreementList")}>
                  <div className="d-flex align-items-center">
                    <FaFileContract />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Agreement
                    </span>
                  </div>
                </Link>
              </li>



              <li className={`nav-item new_menu ${activeMenu === "FAQs" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Faq" onClick={() => handleMenuClick("FAQs")}>
                  <div className="d-flex align-items-center">
                    <MdOutlineContactSupport />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      FAQs
                    </span>
                  </div>
                </Link>
              </li>


              <li className={`nav-item new_menu ${activeMenu === "Contactus" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Contactus" onClick={() => handleMenuClick("Contactus")}>

                  <div className="d-flex align-items-center">
                    <TiContacts />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Contact us
                    </span>
                  </div>
                </Link>

              </li>

              <li className={`nav-item new_menu ${activeMenu === "Aboutus" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Aboutus" onClick={() => handleMenuClick("Aboutus")}>

                  <div className="d-flex align-items-center">
                    <IoPersonOutline />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      About Us
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Termsandcondition" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Termsandcondition" onClick={() => handleMenuClick("Termsandcondition")}>


                  <div className="d-flex align-items-center">
                    <FaRegAddressBook />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Terms &amp; Conditions
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Privacypolicy" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Privacypolicy" onClick={() => handleMenuClick("Privacypolicy")}>


                  <div className="d-flex align-items-center">
                    <GrShieldSecurity />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Privacy policy
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Profile" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Profile" onClick={() => handleMenuClick("Profile")}>

                  <div className="d-flex align-items-center">
                    <CgProfile />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Profile
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Notifications" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Notifications" onClick={() => handleMenuClick("Notifications")}>


                  <div className="d-flex align-items-center">
                    <MdNotificationsActive />


                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Notifications
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Settings" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Settings" onClick={() => handleMenuClick("Settings")}>


                  <div className="d-flex align-items-center">
                    <IoSettingsOutline />


                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Settings
                    </span>
                  </div>
                </Link>

              </li>

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
