import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import watermark from "../../assets/img/gallery/watermark.png";
import "./Agreement.css";
import { FaFileContract } from "react-icons/fa";
import logopdf from "../../assets/img/gallery/logopdf.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PDFGeneratorGujarati = () => {
  const broker_id = localStorage.getItem("broker_id");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [Databaseurl, setDatabaseurl] = useState(null);
  const [SelectYear, setSelectYear] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (event) => {
    setSelectYear(event.target.value);
  };
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };
  const [formData, setFormData] = useState({
    promise_note: "",
    agreement_date: getCurrentDate(),
    party_first: "",
    party_second: "Swan Wealth",
    investment_amount: "",
    annual_return_on_invest: "",
    an_amout_invested: "",
    end_of_year_invest_return: "",
    day_month_year_interestvalue: "",
    day_month_year_interestvalue1: "",
    day_month_year_interestvalue2: "",
    day_month_year_interestvalue3: "",
    day_month_year_interestvalue4: "",
    issues_cheque: "",
  });
  useEffect(() => {
    if (formData.agreement_date) {
      const baseDate = new Date(formData.agreement_date);
      setFormData((prevData) => ({
        ...prevData,
        day_month_year_interestvalue: new Date(
          baseDate.getFullYear() + 1,
          baseDate.getMonth(),
          baseDate.getDate() + 1
        )
          .toISOString()
          .split("T")[0],
        day_month_year_interestvalue1: new Date(
          baseDate.getFullYear() + 2,
          baseDate.getMonth(),
          baseDate.getDate() + 1
        )
          .toISOString()
          .split("T")[0],
        day_month_year_interestvalue2: new Date(
          baseDate.getFullYear() + 3,
          baseDate.getMonth(),
          baseDate.getDate() + 1
        )
          .toISOString()
          .split("T")[0],
        day_month_year_interestvalue3: new Date(
          baseDate.getFullYear() + 4,
          baseDate.getMonth(),
          baseDate.getDate() + 1
        )
          .toISOString()
          .split("T")[0],
        day_month_year_interestvalue4: new Date(
          baseDate.getFullYear() + 5,
          baseDate.getMonth(),
          baseDate.getDate() + 1
        )
          .toISOString()
          .split("T")[0],
      }));
    }
  }, [formData.agreement_date]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "investment_amount" && (value.length > 10 || isNaN(value))) {
      return;
    }
    if (
      name === "annual_return_on_invest" &&
      (value.length > 10 || isNaN(value))
    ) {
      return;
    }
    if (name === "an_amout_invested" && (value.length > 10 || isNaN(value))) {
      return;
    }
    if (
      name === "end_of_year_invest_return" &&
      (value.length > 10 || isNaN(value))
    ) {
      return;
    }

    if (name === "issues_cheque") {
      // This will allow letters (A-Za-z), spaces, numbers (0-9), and commas
      formattedValue = value
        .replace(/[^0-9,\s\u0A80-\u0AFF]/g, "")
        .slice(0, 80);
    }

    if (name === "promise_note") {
      formattedValue = value
        .replace(/[^A-Za-z\s\u0A80-\u0AFF]/g, "")
        .slice(0, 40);
    }
    if (name === "party_first") {
      formattedValue = value
        .replace(/[^A-Za-z\s\u0A80-\u0AFF]/g, "")
        .slice(0, 40);
    }

    if (name === "party_second") {
      formattedValue = value
        .replace(/[^A-Za-z\s\u0A80-\u0AFF]/g, "")
        .slice(0, 40);
    }

    setFormData({ ...formData, [name]: formattedValue });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to  submit Agreement Gujarati  ?!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit it!',
      color: "white",
      background: 'transparent',
    });
  
    if (!result.isConfirmed) {
      return;
    }
    setLoading(true);

    const requiredFields = [
      { field: "investment_amount", name: "Investment Amount" },
      { field: "annual_return_on_invest", name: "annual_return_on_invest" },
      { field: "an_amout_invested", name: "an_amout_invested" },
      { field: "issues_cheque", name: "issues_cheque" },
      { field: "promise_note", name: "promise_note" },
      { field: "agreement_date", name: "agreement_date" },
      { field: "party_first", name: "party_first" },
      { field: "party_second", name: "party_second" },
      // { field: "investment_duration", name: "investment_duration" },
    ];

    // Dynamically add fields based on SelectYear
    if (SelectYear === 1) {
      requiredFields.push({
        field: "day_month_year_interestvalue",
        name: "day_month_year_interestvalue",
      });
    } else if (SelectYear === 3) {
      requiredFields.push(
        {
          field: "day_month_year_interestvalue",
          name: "day_month_year_interestvalue",
        },
        {
          field: "day_month_year_interestvalue1",
          name: "day_month_year_interestvalue1",
        },
        {
          field: "day_month_year_interestvalue2",
          name: "day_month_year_interestvalue2",
        }
      );
    } else if (SelectYear === 5) {
      requiredFields.push(
        {
          field: "day_month_year_interestvalue",
          name: "day_month_year_interestvalue",
        },
        {
          field: "day_month_year_interestvalue1",
          name: "day_month_year_interestvalue1",
        },
        {
          field: "day_month_year_interestvalue2",
          name: "day_month_year_interestvalue2",
        },
        {
          field: "day_month_year_interestvalue3",
          name: "day_month_year_interestvalue3",
        },
        {
          field: "day_month_year_interestvalue4",
          name: "day_month_year_interestvalue4",
        }
      );
    }

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      setShow(true)
      Swal.fire({
        icon: "error",
        title: "Missing Fields!",
        html: `Please fill All Missing Fields.`,
        showConfirmButton: true,
      });
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("user_id", broker_id);
      updatedFormData.append("type", "gujrati");
      updatedFormData.append("investment_duration", SelectYear);
      if (SelectYear == 1) {
        updatedFormData.append(
          "day_month_year_interestvalue",
          formData.day_month_year_interestvalue
        );
      } else if (SelectYear == 3) {
        updatedFormData.append(
          "day_month_year_interestvalue",
          formData.day_month_year_interestvalue
        );
        updatedFormData.append(
          "day_month_year_interestvalue1",
          formData.day_month_year_interestvalue1
        );
        updatedFormData.append(
          "day_month_year_interestvalue2",
          formData.day_month_year_interestvalue2
        );
      } else if (SelectYear == 5) {
        updatedFormData.append(
          "day_month_year_interestvalue",
          formData.day_month_year_interestvalue
        );
        updatedFormData.append(
          "day_month_year_interestvalue1",
          formData.day_month_year_interestvalue1
        );
        updatedFormData.append(
          "day_month_year_interestvalue2",
          formData.day_month_year_interestvalue2
        );
        updatedFormData.append(
          "day_month_year_interestvalue3",
          formData.day_month_year_interestvalue3
        );
        updatedFormData.append(
          "day_month_year_interestvalue4",
          formData.day_month_year_interestvalue4
        );
      }
      updatedFormData.append("status", "1");
      Object.keys(formData).forEach((key) => {
        if (
          ![
            "day_month_year_interestvalue",
            "day_month_year_interestvalue1",
            "day_month_year_interestvalue2",
            "day_month_year_interestvalue3",
            "day_month_year_interestvalue4",
            "day_month_year_interestvalue5",
          ].includes(key)
        ) {
          updatedFormData.append(key, formData[key]);
        }
      });

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}add-swan-selfcompany-agreement`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);
      const data = response.data.status;

      if (data === 1) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          timer: 1500,
        });
        setTimeout(() => {
          navigate("/AgreementList");
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
          timer: 2000,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { error: errorCode, message } = error.response.data;
        if (errorCode === 1) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: message,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An unexpected error occurred.",
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An unexpected error occurred.",
          timer: 2000,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleAgreementChange = (date) => {
    // Update the formData state with the selected date
    setFormData({
      ...formData,
      agreement_date: date ? date.toISOString().split("T")[0] : ""
    });
    console.warn("Selected date:", date);
  };

  const fetchData = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-logo-withoutlogin`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;

      console.warn(response.data);
      const baseurl = response.data.logo_url;

      setData(rawData);
      setDatabaseurl(baseurl);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const generatePDF = () => {
  //   const input = document.getElementById("pdf-content");
  //   html2canvas(input)
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF();
  //       pdf.addImage(imgData, "PNG",  10, 5, 190, 0);
  //       pdf.setTextColor(150, 150, 150);
  //       pdf.save(`agreement_${name || "default"}.pdf`);
  //     })
  //     .catch((error) => {
  //       console.error("Error generating PDF:", error);
  //     });
  // };
  const generatePDF = async (e) => {
    e.preventDefault();
  
    // Confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to submit Agreement-English?!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit it!',
      color: "white",
      background: 'transparent',
    });
  
    // If user cancels the action, stop execution
    if (!result.isConfirmed) {
      return;
    }
  
    const requiredFields = [
      { field: "investment_amount", name: "Investment Amount" },
      { field: "annual_return_on_invest", name: "annual_return_on_invest" },
      { field: "an_amout_invested", name: "an_amout_invested" },
      { field: "issues_cheque", name: "issues_cheque" },
      { field: "promise_note", name: "promise_note" },
      { field: "agreement_date", name: "agreement_date" },
      { field: "party_first", name: "party_first" },
      { field: "party_second", name: "party_second" },
    ];
  
    // Dynamically add fields based on SelectYear
    if (SelectYear === 1) {
      requiredFields.push({
        field: "day_month_year_interestvalue",
        name: "day_month_year_interestvalue",
      });
    } else if (SelectYear === 3) {
      requiredFields.push(
        {
          field: "day_month_year_interestvalue",
          name: "day_month_year_interestvalue",
        },
        {
          field: "day_month_year_interestvalue1",
          name: "day_month_year_interestvalue1",
        },
        {
          field: "day_month_year_interestvalue2",
          name: "day_month_year_interestvalue2",
        }
      );
    } else if (SelectYear === 5) {
      requiredFields.push(
        {
          field: "day_month_year_interestvalue",
          name: "day_month_year_interestvalue",
        },
        {
          field: "day_month_year_interestvalue1",
          name: "day_month_year_interestvalue1",
        },
        {
          field: "day_month_year_interestvalue2",
          name: "day_month_year_interestvalue2",
        },
        {
          field: "day_month_year_interestvalue3",
          name: "day_month_year_interestvalue3",
        },
        {
          field: "day_month_year_interestvalue4",
          name: "day_month_year_interestvalue4",
        }
      );
    }
  
    let validationErrors = {};
    let hasError = false;
  
    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }
  
    setErrors(validationErrors);
  
    if (hasError) {
      setLoading(false);
      setShow(true);
      Swal.fire({
        icon: "error",
        title: "Missing Fields!",
        html: `Please fill All Missing Fields.`,
        showConfirmButton: true,
      });
      return;
    }
  
    Swal.fire({
      title: "Generating PDF...",
      html: "Progress: <strong>0%</strong>",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    const input = document.getElementById("pdf-content");
  
    // Check if the input element exists
    if (!input) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "The content for PDF generation was not found. Please check the element ID.",
        showConfirmButton: true,
      });
      return;
    }
  
    let progress = 0;
  
    const updateProgress = (value) => {
      progress = value;
  
      Swal.update({
        html: `Progress: <strong>${Math.min(progress, 100)}%</strong>`,
      });
    };
  
    html2canvas(input, { useCORS: true })
      .then((canvas) => {
        updateProgress(50);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 5, 190, 0);
        pdf.setTextColor(150, 150, 150);
  
        setTimeout(() => {
          updateProgress(100);
  
          pdf.save(`agreement_${formData.promise_note || "default"}.pdf`);
  
          Swal.fire({
            icon: "success",
            title: "PDF Generated!",
            text: "Your PDF has been successfully created and downloaded.",
            showConfirmButton: true,
          });
        }, 500);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "There was an error generating the PDF. Please try again. If the issue persists, check the console for details.",
          showConfirmButton: true,
        });
        console.error("Error generating PDF:", error);
      });
  };
  
  const [AllEmployee, setAllEmployee] = useState("");
  const [ActiveEmployee, setActiveEmployee] = useState("");
  const [InactiveEmployee, setInactiveEmployee] = useState("");
  const fetchData2 = async () => {
    try {
      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-agreement-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;
      const totalEmp = response.data.totalAgreements;
      const ActiveEmployee = response.data.totalAgreementsEng;
      // alert(ActiveEmployee)
      const InactiveEmployee = response.data.totalAgreementsGuj;

      setAllEmployee(totalEmp);
      setActiveEmployee(ActiveEmployee);
      setInactiveEmployee(InactiveEmployee);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData2();
    fetchDataa();
    getProfile();
  }, []);

  const [logoData, setLogoData] = useState(null);
  const [Baseurl, setBaseurl] = useState(null);

  const fetchDataa = async () => {
    try {
     
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-logo-withoutlogin`,
       
      };

      const response = await axios(config);
      const data = response.data;
      setLogoData(data.data.company_logo);
      setBaseurl(data.logo_url)
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  };
  const [formData1, setFormData1] = useState();

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}show-swan-selfcompany-Profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    //  if (response.data.status === 0) {
    //   localStorage.clear();
    //   navigate("/");
    // }
 
      const data = response.data.data;
      
      const baseurl = response.data.image_url;

      // setBaseUrl(baseurl);
      // localStorage.setItem("baseurl", baseurl);
      // setFormData(data[0]);
      setFormData1(data[0].name);
     
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      console.warn("Error fetching user profile:", errorMessage);

      if (errorMessage === "Unauthenticated") {
      } else {
        console.error("Error fetching user profile:", error);
      }
    }
  };

  
  return (
    <div className="container-fluid">
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-10">
              <h3 className="mb-0">Agreement Analytics</h3>
            </div>
            <div className="col-lg-2 text-end">
              <Link to="/AgreementList" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-4">
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
                Total Agreement
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <FaFileContract className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                    {AllEmployee}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xxl-4">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Agreement English</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <FaFileContract className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {ActiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xxl-4">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Agreement Gujarati</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <FaFileContract className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {InactiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-4">
              <h3 className="mb-0">Agreement List</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body bg-body-tertiary">
          <div className="content_pdf" id="pdf-content">
            <div className="logoswan pt-5">
              {/* <img src={`${Databaseurl}/${data.selfcompany_logo}`} alt="Logo" /> */}
              <img  src={`${Baseurl}/${logoData}`} alt="Logo" />
            </div>
            <div className="d-flex justify-content-end">
              <h3>
                તારીખ : {new Date(formData.agreement_date)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}
               
              </h3>
            </div>
            <div className="contentall">
              <p>
                {formData1} અને {formData.promise_note}
               
                વચ્ચેની આ પ્રોમિસરી નોટ.
              </p>
              <p>
                પક્ષ 1 (નાણાં આપનાર) : {formData.party_first}
                
              </p>
              <p>
                પક્ષ 2 (નાણાં લેનાર) : {formData.party_second}
             
              </p>
              <p className="fw-bold">ઇન્વેસ્ટમેન્ટ ની શરતો :</p>
              <p>
                નાણા રોકાણની રકમ : રૂ. {formData.investment_amount}
                
                /-
                <br /> નાણા રોકાણ નું વાર્ષિક વળતર: રૂ.{" "}
                {formData.annual_return_on_invest}
                
                /-
              </p>
              <p>
                રોકાણ કરવાની લઘુત્તમ અવધી:{" "} {SelectYear} વર્ષ
               
              
              </p>
              <p>
                {formData1} માં તારીખ: {new Date(formData.day_month_year_interestvalue)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}
               
                ના રોજ રૂ. {formData.an_amout_invested}
                
                /- રોકાણ કરેલ છે.
              </p>
              <p>રોકાણ માટે લીધેલા નાણાં નું વાર્ષિક વળતર ચુકવવા પાત્ર.</p>
              {SelectYear == 1 ? (
               <div className="1_year">
               <p className="amtount">
                 <div>
                   {new Date(formData.day_month_year_interestvalue)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}
                 </div>
                 Rs. {formData.annual_return_on_invest}
               </p>
             </div>
             
              ) : SelectYear == 3 ? (
                <div className="3_year">
                  <p className="amtount">
                  <div>
                    {new Date(formData.day_month_year_interestvalue)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}</div>
                   
                    Rs. {formData.annual_return_on_invest}
                   
                  </p>
                  <p className="amtount">
                    {" "}
                    <div>{new Date(formData.day_month_year_interestvalue1)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}</div>
                  
                    Rs. {formData.annual_return_on_invest}
                  
                  </p>
                  <p className="amtount">
                    {" "}
                    <div>{new Date(formData.day_month_year_interestvalue2)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}</div>
                   
                    Rs. {formData.annual_return_on_invest}
                  
                  </p>
                </div>
              ) : (
                <div className="5_year">
                  <p className="amtount">
                  <div>
                   {new Date(formData.day_month_year_interestvalue)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}</div>
                  
                    Rs. {formData.annual_return_on_invest}
                    
                  </p>
                  <p className="amtount">
                  <div>
                  {new Date(formData.day_month_year_interestvalue1)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}</div>
                  
                    Rs. {formData.annual_return_on_invest}
                   
                  </p>
                  <p className="amtount">
                  <div>
                  {new Date(formData.day_month_year_interestvalue2)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}</div>
                  
                    Rs. {formData.annual_return_on_invest}
                    
                  </p>
                  <p className="amtount">
                  <div>
                  {new Date(formData.day_month_year_interestvalue3)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}</div>
                  
                    Rs. {formData.annual_return_on_invest}
                    
                  </p>
                  <p className="amtount">
                  <div>
                  {new Date(formData.day_month_year_interestvalue4)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}</div>
                   
                    Rs. {formData.annual_return_on_invest}
                   
                  </p>
                </div>
              )}
              <p>
                વર્ષના અંતે રોકેલ નાણા રૂ. {formData.end_of_year_invest_return}
                
                /- ની રકમ પરત કરવામાં આવશે.
              </p>
              <p>
                આપેલ ચેક વિગત:
                <u className="ms-1">
                  {formData.issues_cheque}
                
                </u>
              </p>
              <p>
                ડિફોલ્ટની ઘટનામાં ઇન્વેસ્ટમેન્ટ કરેલા નાણાં અને વળતર નિશ્ચિત
                તારીખે સંપૂર્ણ ચુુકવણી કરવામાં નિષ્ફળ જાય તો અવેતન મુદ્દલ કાયદા
                દ્વારા માન્ય મહતમ દરે વળતર મેળવશે જ્યાં સુધી ઇન્વેસ્ટમેન્ટ લેનાર
                ડિફોલ્ટમાં ન રહે
              </p>
              <p>
                જો કોઈપણ કારણસર કુદરતી આફત જેવી કે કોવિડ, લોકડાઉન વગેરે એટલા સમય
                પૂરતું ગ્રાહકને ઇન્વેસ્ટમેન્ટ નું વળતર મળવા પાત્ર થશે નહીં.
              </p>
              <p>
                સમગ્ર કરાર આ નોંધમાં કોઈપણ જોડાણો અથવા પરિશિષ્ટો સહિત તેના વિષય
                સંબંધિત પક્ષકારો દ્વારા સંમત થયેલી તમામ શરતો સામેલ છે. આ નોંધ
                તમામ ચચાર્ઓ સમજૂતીઓ અને મૌખિક કરારોને બદલે છે. નાણાં આપનાર અને
                નાણાં લેનાર એમ બંને નિયમો અને શરતો સાથે સંમત થાય છે.અને જ્યાં
                સુધિ ઇન્વેસ્ટમેન્ટ લેનાર નાણાની સંપૂર્ણ ચુુકવણી ન કરે ત્યાં સુધિ
                બંધાયેલા રહેશે.
              </p>
              <p>
                ગ્રાહકનું નામ અને સહી ______________________________________
              </p>
              <span style={{ height: "30px", display: "block" }}></span>
              <p>
                કંપનીનો સ્ટેમ્પ અને સહી ______________________________________
              </p>
            </div>
            <img className="preview" src={watermark} alt="Watermark Preview" />
            <div className="text-center footer_link">
              <a href="#" className="fw-bold">
                <i className="bi bi-telephone"></i>+91 {data.owner_mobile},
              </a>
              <p className="pb_30">
                <i className="bi bi-geo-alt"></i> {data.address}
              </p>
            </div>
          </div>

          <div className="col-lg-12 text-end">
            <button className="btn bg-success text-light" onClick={generatePDF}>
              Generate PDF
            </button>
            <button
              className="btn bg-primary text-light mx-2"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <Button variant="primary" onClick={handleShow}>
              Edit
            </Button>
          </div>
          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Fill Agreement Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="promiseNote">
                  <Form.Label>વચન નોંધ</Form.Label>
                  <Form.Control
                    className={` ${errors.promise_note ? "input-error" : ""}`}
                    type="text"
                    name="promise_note"
                    value={formData.promise_note}
                    onChange={handleInputChange}
                    placeholder="વચન નોંધ દાખલ કરો"
                  />
                </Form.Group>

                <Form.Group controlId="agreementDate">
                  <Form.Label>કરાર તારીખ</Form.Label>
                  <DatePicker
                  className={`form-control ${errors.agreement_date ? "input-error" : ""}`}
                  selected={formData.agreement_date ? new Date(formData.agreement_date) : null}
                  onChange={handleAgreementChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD-MM-YYYY"
                  // maxDate={currentDate} 
                  customInput={
                    <InputMask
                      mask="99/99/9999"
                      maskChar={null} // Remove placeholder character (for clarity)
                      value={formData.agreement_date}
                      onChange={handleAgreementChange}
                    />
                  }
                />
                </Form.Group>

                <Form.Group controlId="partyFirst">
                  <Form.Label>પ્રથમ પક્ષ</Form.Label>
                  <Form.Control
                    type="text"
                    name="party_first"
                    value={formData.party_first}
                    onChange={handleInputChange}
                    placeholder="પ્રથમ પક્ષ દાખલ કરો"
                  />
                </Form.Group>

                <Form.Group controlId="investmentAmount">
                  <Form.Label>નિવેશિત રકમ</Form.Label>
                  <Form.Control
                    type="number"
                    name="investment_amount"
                    value={formData.investment_amount}
                    onChange={handleInputChange}
                    placeholder="નિવેશિત રકમ દાખલ કરો"
                  />
                </Form.Group>

                <Form.Group controlId="annualReturn">
                  <Form.Label>વાર્ષિક પરત</Form.Label>
                  <Form.Control
                    type="number"
                    name="annual_return_on_invest"
                    value={formData.annual_return_on_invest}
                    onChange={handleInputChange}
                    placeholder="વાર્ષિક પરત % દાખલ કરો"
                  />
                </Form.Group>

                <Form.Group controlId="amountInvested">
                  <Form.Label>નિવેશિત રકમ</Form.Label>
                  <Form.Control
                    type="number"
                    name="an_amout_invested"
                    value={formData.an_amout_invested}
                    onChange={handleInputChange}
                    placeholder="નિવેશિત રકમ દાખલ કરો"
                  />
                </Form.Group>

                <Form.Group controlId="investment_duration">
                <Form.Label>નિવેશ અવધિ પસંદ કરો</Form.Label>
  <Form.Select
    id="investment_duration"
    name="investment_duration"
    value={SelectYear}
    onChange={handleChange}
  >
    <option value={1}>1 વર્ષ</option>
    <option value={3}>3 વર્ષ</option>
    <option value={5}>5 વર્ષ</option>
  </Form.Select>
</Form.Group>

                <Form.Group controlId="endOfYearReturn">
                  <Form.Label>વર્ષના અંતે પરત</Form.Label>
                  <Form.Control
                    type="number"
                    name="end_of_year_invest_return"
                    value={formData.end_of_year_invest_return}
                    onChange={handleInputChange}
                    placeholder="વર્ષના અંતે પરત દાખલ કરો"
                  />
                </Form.Group>

                <Form.Group controlId="issuesCheque">
                  <Form.Label>ચેકની વિગતો</Form.Label>
                  <Form.Control
                    type="text"
                    name="issues_cheque"
                    value={formData.issues_cheque}
                    onChange={handleInputChange}
                    placeholder="ચેકની વિગતો દાખલ કરો"
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
          
              <Button variant="primary" onClick={handleClose}>
                View
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PDFGeneratorGujarati;
