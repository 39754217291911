import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ImCross } from "react-icons/im";

export default function Settings() {
  const brokerId = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileImage, setfileImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [BaseUrl, setBaseUrl] = useState("");
  const [baseurlS, setBaseurlS] = useState("");
  const [formData, setFormData] = useState({
    company_name: "",
    primary_number: "",
    secondary_number: "",
    wealth_short_desc: "",
    site_url: "",
    address: "",
    wealth_logo: null,
  });

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  //     if (!validTypes.includes(file.type)) {
  //       setNewImage(null);
  //       setfileImage(null);

  //       Swal.fire({
  //         title: 'Invalid File Type',
  //         text: 'Only JPEG, JPG, and PNG files are allowed!',
  //         icon: 'error',
  //         confirmButtonText: 'OK',
  //       });
  //       return;
  //     }

  //     setfileImage(URL.createObjectURL(file)); // Preview the image
  //     setNewImage(file); // Save the file for upload
  //   } else {
  //     setfileImage(null); // Reset preview if no file is selected
  //     setNewImage(null); // Reset file state
  //   }
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImageProfile();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setfileImage(URL.createObjectURL(file));
        setNewImage(file);
      }
    }
  };
  const handleClearImageProfile = () => {
    if (fileImage) {
      URL.revokeObjectURL(fileImage); // Revoke the old object URL
    }
    setfileImage("");
    setNewImage(null);
    const fileInput = document.getElementById("wealth_logo");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };




  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Phone number validation for primary and secondary numbers
    if (name === 'primary_number' || name === 'secondary_number') {
      if (/^\d{0,10}$/.test(value)) {  // Only allow numbers up to 10 digits
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));

        // Set error if it's not exactly 10 digits when the user stops typing
        if (value.length === 10) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: null,  // Clear error
          }));
        } else if (value.length > 0 && value.length < 10) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Phone number must be exactly 10 digits",
          }));
        }
      }
    } else {
      // Handle other input fields normally
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };



  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", brokerId);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}view-swan-selfcompany-general-settings`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const profileData = response.data.data;

      if (profileData) {
        setFormData({
          company_name: profileData.company_name || "",
          primary_number: profileData.primary_number || "",
          secondary_number: profileData.secondary_number || "",
          wealth_short_desc: profileData.company_short_desc || "",
          address: profileData.address || "",
          id: profileData.id || "",
          wealth_logo: profileData.company_logo || null,
          site_url: profileData.site_url || null,
        });
        setBaseUrl(response.data.SwanCompanyGeneralSettings_url);
      } else {
        console.error("No profile data found.");
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to Update Company Settings ?!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit it!',
      color: "white",
      background: 'transparent',
    });
  
    if (!result.isConfirmed) {
      return;
    }
    setLoading(true);

    try {
      const updatedFormData = new FormData();
      updatedFormData.append("id", formData.id);
      updatedFormData.append("company_name", formData.company_name);
      updatedFormData.append("primary_number", formData.primary_number);
      updatedFormData.append("secondary_number", formData.secondary_number);
      updatedFormData.append("company_short_desc", formData.wealth_short_desc);
      updatedFormData.append("address", formData.address);
      updatedFormData.append("site_url", formData.site_url);

      if (newImage) {
        updatedFormData.append("wealth_logo", newImage);
      }

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}update-swan-selfcompany-general-settings`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);

      if (response.data.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          color: "white",
          background: "transparent",
        });
        // window.location.reload();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update settings",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Update Company Settings</h3>
            </div>
            {/* <div className="col-lg-4 text-end">
              <Link to="/home">
                <button className="btn btn-success">Back</button>
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      <div className="card mb-6">
        <form className="card-body" onSubmit={handleSubmit}>
          <div className="row g-3 mt-2">
            {/* Company Name */}
            <div className="col-md-6">
              <label className="form-label">Company Name</label>
              <input
                className="form-control"
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
              />
            </div>

            {/* Primary Number */}
            <div className="col-md-6">
              <label className="form-label">Primary Number</label>
              <input
                className="form-control"
                type="text"
                name="primary_number"
                value={formData.primary_number}
                onChange={handleInputChange}
              />
            </div>

            {/* Secondary Number */}
            <div className="col-md-6">
              <label className="form-label">Secondary Number</label>
              <input
                className="form-control"
                type="text"
                name="secondary_number"
                value={formData.secondary_number}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Site URL</label>
              <input
                className="form-control"
                type="text"
                name="site_url"
                value={formData.site_url}
                onChange={handleInputChange}
              />
            </div>

            {/* Short Description */}
            <div className="col-md-6">
              <label className="form-label">Short Description</label>
              <textarea
                className="form-control"
                type="text"
                name="wealth_short_desc"
                value={formData.wealth_short_desc}
                onChange={handleInputChange}
              />
            </div>

            {/* Address */}
            <div className="col-md-6">
              <label className="form-label">Address</label>
              <textarea
                className="form-control"
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>

            {/* Wealth Logo */}
            {/* <div className="col-md-6">
              <label className="form-label">Company Logo</label>
              <input
                className="form-control"
                type="file"
                accept=".jpeg,.jpg,.png"
                name="wealth_logo"
                onChange={handleImageChange}
              />
              {fileImage ? (
                <img
                  src={fileImage}
                  alt="Wealth Logo Preview"
                  style={{ width: "100px", height: "100px", marginTop: "10px" }}
                />
              ) : (
                <img
                  src={`${BaseUrl}/${formData.wealth_logo}`}
                  alt="Preview"
                  style={{ maxWidth: "100px", maxHeight: "100px", marginTop: "10px" }}
                />
              )}
            </div> */}

            <div className="col-md-6">
              <label className="form-label">Company Logo</label>
              <input
                className="form-control"
                type="file"
                id="wealth_logo"

                accept=".jpeg,.jpg,.png"
                name="wealth_logo"
                onChange={handleImageChange}
              />

              {fileImage ? (

                <div className="image_box">
                  <img
                    className="m-2"
                    src={fileImage}
                    alt="Selected Plan"
                    style={{ height: "150px", width: "150px" }}
                  />
                  <ImCross onClick={handleClearImageProfile} />
                </div>
              ) : (
                <div className="image_box">
                  <img
                    className="m-2"
                    src={`${BaseUrl}/${formData.wealth_logo}`}
                    alt="Selected Plan"
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>

               
               
              )}
            </div>

          </div>

          <div className="pt-4 w-100 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
