import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
export default function CustomerMessagehistories() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(""); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const customer_id = localStorage.getItem("customer_id");
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      
      formData.append("cust_id", customer_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}swan-selfcompany-user-investment-Expired-plans-history-log`,
        
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
 
  const filteredData = data
  ? data.filter((item) =>
      item.cust_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  : [];
   

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Customer Plan Expiry History</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Custumerdetails" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      <div className="card">
      <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by name"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Cust.Name</th>
                  <th>P.Name</th>
                  <th>Comp.Name</th>
                  <th>Interest Rate(CAGR)</th>
                  <th>Invested Amount</th>
                  <th>Expected Profit Amount</th>
                  <th>Duration</th>
                  <th>Is Compound</th>
                  <th>Status</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {paginatedData.length > 0 ? (
    paginatedData.map((item, index) => (
        <tr key={item.id || index}>
            <td>{startIndex + index + 1}</td>
            <td>{item.cust_name || "N/A"}</td>
            <td>{item.plan_name || "N/A"}</td>
            <td>{item.company_name || "N/A"}</td>
            <td>{item.interest_rate ? `${item.interest_rate}%` : "N/A"}</td>
            <td>{item.invested_amount || "N/A"}</td>
            <td style={{ color: "green" }}>{item.expired_amount || "N/A"}</td>
            <td>{item.plan_duration ? `${item.plan_duration} Year` : "N/A"}</td>
            <td>{item.is_compound ? "Yes" : "No"}</td>
            <td className={item.action === "plan_expired" ? "text-danger" : ""}>
                {item.action === "plan_expired" && "Plan Expired"}
            </td>
            <td>{item.plan_duration_from || "N/A"}</td>
            <td>{item.plan_duration_to || "N/A"}</td>
            <td className="align-middle priority pe-4">
            <div className="d-flex gap-1 action_button">
            <OverlayTrigger
                            key="GenerateBill"
                            placement="top"
                            overlay={
                              <Tooltip id={`GenerateBill`}>
                                Generate 
                              </Tooltip>
                            }
                          >
                            <button
                              style={{ "--i": "red" }}
                              // onClick={() => GenerateBill(item.cust_id)}
                            >
                              {" "}
                              PDF
                              {/* <FaFilePdf className="color_link" /> */}
                            </button>
                          </OverlayTrigger>
                          </div>
            </td>
        </tr>
    ))
) : (
    <tr>
        <td colSpan="12" className="text-center py-4">
            No data found.
        </td>
    </tr>
)}

              </tbody>
           
            </table>
          </div>
          {totalPages > 1 && (
                <div className="pagination mt-3 mb-3 mx-3">
                  <button
                    className="btn btn-sm btn-light"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <IoIosArrowBack />
                  </button>
                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <button
                      key={pageNumber + 1}
                      className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                        ? "btn-light text-primary"
                        : "btn-light"
                        }`}
                      onClick={() => handlePageChange(pageNumber + 1)}
                    >
                      {pageNumber + 1}
                    </button>
                  ))}
                  <button
                    className="btn btn-sm btn-light"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <IoIosArrowForward />
                  </button>
                </div>
              )}
        </div>
      </div>
    </>
  );
}
