import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";

export default function Investmenthistories() {
  const [data, setData] = useState([]);
  const [RemainsBalance, setRemainsBalance] = useState();
  const navigate = useNavigate();
  const customer_id = localStorage.getItem("customer_id");
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}swan-selfcompany-use-self-balance-for-investment-history-logs`,
        {}, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRemainsBalance(response.data.RemainsBalance);
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="mb-0">Earn Amount History</h3>
            </div>
            <div className="col-lg-4 text-end">
            <h3 class="mb-0 text-success"> <MdOutlineAccountBalanceWallet className="fs-4 text-success" />{" "}
            {RemainsBalance}</h3>
            </div>
            <div className="col-lg-2 text-end">
              <Link to="/Company-Earn" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Payment Type</th>
                  <th>Transaction Amount</th>
                  <th>Transaction Date</th>
                  <th>Transaction Date Timings</th>
                  <th>Type</th>
                  
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.Company_name}</td>
                      <td style={{ color: item.payment_type == 'Debit' ? 'red' : item.payment_type === 'Credit' ? 'green' : 'black' }}>
                      {item.payment_type}
                    </td>
                      <td>{item.transaction_amount}</td>
                      <td>{item.transaction_date}</td>
                      <td>{item.transaction_dateTimings}</td>
                      <td>{item.type}</td>
                    
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
         
        </div>
      </div>
    </>
  );
}
