import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Swal from "sweetalert2";
import { GiReceiveMoney } from "react-icons/gi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { FaFileContract } from "react-icons/fa";
export default function Investmentinsawn() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [AllEmployee, setAllEmployee] = useState("");
  const [ActiveEmployee, setActiveEmployee] = useState("");
  const [InactiveEmployee, setInactiveEmployee] = useState("");

  const navigate = useNavigate();
  const custumerdetails_id = localStorage.getItem("custumerdetails");

  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-agreement-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;
      const totalEmp = response.data.totalAgreements;
      const ActiveEmployee = response.data.totalAgreementsEng;
      // alert(ActiveEmployee)
      const InactiveEmployee = response.data.totalAgreementsGuj;

      setAllEmployee(totalEmp);
      setActiveEmployee(ActiveEmployee);
      setInactiveEmployee(InactiveEmployee);

      setbaseurl(data1);
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchDataPDF = async (id) => {
    try {
      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const config = {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}generate-old-agreement-swan-selfcompany-agreement?id=${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const data = response.data.data;

      // Open the PDF or URL in a new tab
      if (data && data.pdfUrl) {
        window.open(data.pdfUrl, "_blank"); // '_blank' opens the URL in a new tab
      } else {
        console.error("No PDF URL returned");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  
  }, []);

  const filteredData = data
    ? data.filter((item) =>
        item.party_first.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleClick = (id) => {
    localStorage.setItem("custumerdetails", id);
    navigate("/Custumerdetails");
  };

  const handleClickadd = () => {
    navigate("/Agreement-Gujarati");
  };
  const handleClickaddenglish = () => {
    navigate("/Agreement-English");
  };

  const handleClickinvestmentId = (id) => {
    localStorage.setItem("Agreement_id", id);
    navigate("/Agreement-Guj-View");
  };
  const handleClickinvestmentId1 = (id) => {
    localStorage.setItem("Agreement_id", id);
    navigate("/Agreement-Eng-View");
  };

  const handleClickinvestmenteditId = (id) => {
    localStorage.setItem("investment_id", id);
    navigate("/Investmentedit");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Agreement Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              {/* <Link to="/Home" className="">
                <button className="btn btn-success">Back</button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-4">
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
                Total Agreement
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <FaFileContract className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                    {AllEmployee}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xxl-4">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Agreement English</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <FaFileContract className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {ActiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xxl-4">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Agreement Gujarati</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <FaFileContract className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {InactiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-4">
              <h3 className="mb-0">Agreement List</h3>
            </div>
            <div className="col-lg-8 text-end">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={handleClickaddenglish}
              >
                <IoMdAdd className="me-1 fs--2" /> English Agreement
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success mx-1"
                onClick={handleClickadd}
              >
                <IoMdAdd className="me-1 fs--2" /> Gujarati Agreement
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-header">
          <div className="card-header w-100 border-bottom border-200">
            <div className="justify-content-end d-flex w-100">
              <div className="justify-content-end mt-1 d-flex w-100">
                <form>
                  <div className="input-group input-search-width">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Search by Plan Name"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm mb-0 fs--1">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle">Sr.</th>
                  <th className="text-800 sort align-middle">Party First</th>
                  <th className="text-800 sort align-middle">Party Second</th>
                  <th className="text-800 sort align-middle">Type</th>
                  <th className="text-800 sort align-middle">
                    Investment Amount
                  </th>
                  {/* <th className="text-800 sort align-middle">Annual Return on Invest</th>
      <th className="text-800 sort align-middle">End of Yr Invest Return</th> */}
                  {/* <th className="text-800 sort align-middle">Issued Cheque</th> */}
                  <th className="text-800 sort align-middle">Duration</th>
                  <th className="text-800 sort align-middle">Agreement Date</th>
                  <th className="text-800 sort align-middle">Interest Date</th>
                  <th className="text-800 sort align-middle">Action</th>
                </tr>
              </thead>
              <tbody className="list capitalize small-font">
                {loading ? (
                  <tr>
                    <td colSpan="13" className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : currentData.length === 0 ? (
                  <tr>
                    <td colSpan="13" className="text-center">
                      No records found
                    </td>
                  </tr>
                ) : (
                  currentData.map((item, index) => (
                    <tr key={item.id}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>{item.party_first}</td>
                      <td>{item.party_second}</td>
                      <td>{item.type}</td>
                      <td>{item.investment_amount}</td>
                      {/* <td>{item.end_of_year_invest_return}</td>
          <td>{item.annual_return_on_invest}</td> */}
                      {/* <td>{item.issues_cheque}</td> */}
                      <td>{item.investment_duration} Year</td>

                      <td>{item.agreement_date}</td>
                     
                      <td> {new Date(item.day_month_year_interestvalue)
                     .toLocaleDateString('en-GB')
                     .replace(/\//g, '-')}</td>
                      <td>
                        <div className="d-flex gap-1 action_button">
                          {item.type === "english" ? (
                            <OverlayTrigger
                              key="View"
                              placement="top"
                              overlay={
                                <Tooltip id={`View`}>
                                  Agreement English View
                                </Tooltip>
                              }
                            >
                              <button
                                type="button"
                                onClick={() =>
                                  handleClickinvestmentId1(item.id)
                                }
                                style={{ "--i": "#07bc0c" }}
                              >
                                <FaEye className="color_link" />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              key="View"
                              placement="top"
                              overlay={
                                <Tooltip id={`View`}>
                                  Agreement Gujarati View
                                </Tooltip>
                              }
                            >
                              <button
                                type="button"
                                onClick={() => handleClickinvestmentId(item.id)}
                                style={{ "--i": "#07bc0c" }}
                              >
                                <FaEye className="color_link" />
                              </button>
                            </OverlayTrigger>
                          )}
                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>PDF View</Tooltip>}
                          >
                            <button
                              type="button"
                              onClick={() => {
                                // Open the URL in a new tab
                                window.open(
                                  `https://broker.swaninvestment.in/API/public/api/generate-old-agreement-swan-selfcompany-agreement?id=${item.id}`,
                                  "_blank"
                                );
                              }}
                              className="bg-danger"
                            >
                              PDF
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <div className=" mt-3 mb-3">
              <button
                className="btn btn-sm btn-light"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <IoIosArrowBack />
              </button>

              <span className="align-self-center">
                Page {currentPage} of {totalPages}
              </span>

              <button
                className="btn btn-sm btn-light"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
