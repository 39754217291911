import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { EyeFill, EyeSlash } from 'react-bootstrap-icons';
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ChangePass() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  // const email = localStorage.getItem('email');
  const location = useLocation();
  const email = location.state?.email;
  const token = localStorage.getItem("bear_token");

  const navigate = useNavigate();

  const onInputChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onInputChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  const Submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
    
      if (password.length < 6) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Password must be at least 6 characters long!",
          // timer: 1500
        });
        
        setLoading(false);
        return;
      }
      if (password !== confirmPassword) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Passwords do not match!",
          // timer: 1500
        });
      
        setLoading(false);
        return;
      }
      const confirmResetPassword = await Swal.fire({
        title: 'Reset Password',
        text: "Are you sure you want to reset the password? This action cannot be undone.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6', // Blue for confirmation
        cancelButtonColor: '#d33', // Red for cancel
        confirmButtonText: 'Yes, Reset!',
        cancelButtonText: 'Cancel',
      });
      
      if (!confirmResetPassword.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'Password reset was cancelled.',
        });
        return;
      }
      
      const url = `${process.env.REACT_APP_API_URL}swan-selfcompany-reset-password`;

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      };
      
      const formData = new FormData();
      formData.append("password", password);
      formData.append("repeat_password", password);
      
      const config = {
        method: "POST",
        url: url,
        headers: headers,
        data: formData
      };
      axios(config)
        .then(function (response) {
          if (response.data.status === 1) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
              color:"white",
              background: 'transparent',
              // timer: 1500
            });
            // toast.success(response.data.message);
            setTimeout(() => {
              navigate('/');
            }, 1000);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.data.message,
              // timer: 1500
            });
          
          }
        })
        .finally(() => {
          setLoading(false);
        });

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Change Password</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Investment-management" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Container fluid>
            <Row>
              <div className="col-md-6 form_details">
                <div className="form-group local-forms">
                <label className="form-label" htmlFor="password">
                          Enter New Password
                        </label>
                        <input
                          value={password}
                          onChange={onInputChangePassword}
                          className="form-control"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter New Password"
                          id="password"
                        />
                        
                        {showPassword ? (
                                  <EyeSlash className="fs-3 eye3" onClick={togglePasswordVisibility} 
                                  style={{ height: "22px" }} />
                                ) : (
                                  <EyeFill className="fs-3 eye3" onClick={togglePasswordVisibility} 
                                  style={{ height: "22px" }} />
                                )}
                </div>
              </div>
              <div className="col-md-6 form_details">
                <div className="form-group local-forms">
                <label className="form-label" htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <input
                          value={confirmPassword}
                          onChange={onInputChangeConfirmPassword}
                          className="form-control"
                          type={showPassword ? "text" : "password"}
                          placeholder="Re-enter Your Password"
                          id="confirmPassword"
                        />
  
                                {showPassword ? (
                                  <EyeSlash className="fs-3 eye4" onClick={togglePasswordVisibility} 
                                  style={{ height: "22px" }} />
                                ) : (
                                  <EyeFill className="fs-3 eye4" onClick={togglePasswordVisibility} 
                                  style={{ height: "22px" }} />
                                )}
                             
                </div>
              </div>
        
              <Col md={12}>
                <div className="mb-3 d-flex justify-content-end">
                  <button
                    onClick={Submit}
                    disabled={loading}
                    className="btn btn-sm btn-primary"
                    type="submit"
                  >
                    Submit
                    {loading && (
                      <Spinner
                        animation="border"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
