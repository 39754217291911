import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Link,useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
export default function Addplan() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const Addplan_id = localStorage.getItem("Addplan_id");
  const company_id = localStorage.getItem("company_id");
  const cidwallet = localStorage.getItem("customer_id");
  const [businessss, setBusiness] = useState(null);
  const [data, setData] = useState([]);
  const [user_credit, setuser_credit] = useState();
  const [newImage, setNewImage] = useState();
  const [fileimage, setFileimage] = useState();
  const [investment, setinvestment] = useState();
  const [errors, setErrors] = useState({});


  const [datacom, setDatacompny] = useState([]);
  const [datatype, setDatatype] = useState([]);
  const [dataplan, setDataplan] = useState([]);

  const [formData, setFormData] = useState({
    description: "",
    plan_duration: "",
    plan_duration_type: "",
    company_id: company_id,
    interest_rate: '',

  });
  const [formData11, setFormData11] = useState({
 
    company_id: company_id,
    invested_amount: "",
    invest_plain_id: '',


  });
  const [dataformData, setDataformData] = useState(null);
 
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    setFileimage(URL.createObjectURL(file));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    
    if (name === "invested_amount") {
      // Validate transaction amount: numeric, max 12 digits, no leading zeros
      if (/^[0-9]*$/.test(value)) {
        formattedValue = value.replace(/^0+/, ""); // Remove leading zeros
        if (formattedValue.length > 12) {
          formattedValue = formattedValue.slice(0, 12); // Truncate to 12 digits
        }
      } else {
        return; // Exit for invalid input
      }
    }
    if (name === "invest_plain_id") {
      setDataformData((prevData) => (
       value
       
      ));
    }
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  const handleInputChange11 = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // if (name === "invested_amount" && (value.length > 15 || isNaN(value))) {
    //   return;
    // }
    // if (name === "invested_amount") {
    //   // Allow numeric input with a max length of 12 digits
    //   if (/^\d{0,12}$/.test(value)) {
    //     // Remove leading zeros but keep "0" if input is just "0"
    //     formattedValue = value.replace(/^0+(?=\d)/, "");
        
    //     // Ensure "0" is entered if the field is cleared
    //     if (formattedValue === "") {
    //       // formattedValue = "";
    //     }
    //   } else {

    //     return; // Do not update the state for invalid input
    //   }
    // }
  
    
    if (name === "invested_amount") {
      // Validate transaction amount: numeric, max 12 digits, no leading zeros
      if (/^[0-9]*$/.test(value)) {
        formattedValue = value.replace(/^0+/, ""); // Remove leading zeros
        if (formattedValue.length > 12) {
          formattedValue = formattedValue.slice(0, 12); // Truncate to 12 digits
        }
      } else {
        return; // Exit for invalid input
      }
    }
    if (name === "invest_plain_id") {
      setDataformData((prevData) => (
       value
       
      ));
    }
    setFormData11({ ...formData, [name]: formattedValue });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  useEffect(() => {
    fetchData();
    fetchDatacompny();
    fetchData1();
    fetchDataine_plan();
  }, []);



  const fetchDataine_plan = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("user_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-investment-active-plans-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setDataplan(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDatacompny = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-company-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setDatacompny(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-paln-duration`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;

      // Transform the data into the expected format
      const transformedData = rawData.map((item) => ({
        id: item,
        name: `${item}`, // Assuming you want to display "Duration <number>"
      }));

      console.warn(transformedData);

      // Set transformed data
      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData1 = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-plan-duration-type`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;

      const transformedData = rawData.map((item) => ({
        id: item,
        name: `${item}`,
      }));

      console.warn(transformedData);

      // Set transformed data
      setDatatype(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = [
      // { field: "invest_plain_id", name: "Invest Plan Name" },
      // { field: "invested_amount", name: "Invested Amount" },
      // { field: "interest_rate", name: "interes rate" },

    ];

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to add a new investment plan?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Add Plan!',
      cancelButtonText: 'Cancel',
    });
    
    if (!confirmResult.isConfirmed) {
      setLoading(false);
      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'Adding new plan was canceled by the user.',
      });
      return;
    }
    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("user_id", broker_id);
      updatedFormData.append("cust_id", Addplan_id);
      updatedFormData.append("invest_plain_id", dataformData);
      updatedFormData.append("invested_amount", formData11.invested_amount);
      updatedFormData.append("company_id", 1);
      updatedFormData.append("status", "1");
      Object.keys(formData).forEach((key) => {
        updatedFormData.append(key, formData[key]);
      });
      updatedFormData.append("Plan_image", newImage);
   
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}invest-more-swan-selfcompany-customers`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);
      const data = response.data.status;

      if (data === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
  
        });
        setTimeout(() => {
          navigate("/Custumerdetails");
        }, 2000);
      } else {
        
        if (user_credit <= formData.invested_amount || response.data.message.trim() === `Sorry you dont have sufficient balance please add amount in your wallet first ${user_credit}`) {

          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Insufficient amount: You only have ${user_credit} available.`,
            showCancelButton: true, 
            confirmButtonText: 'Add Fund',
            cancelButtonText: 'Cancel', 
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/Add-Normal-Transaction');
            } else if (result.isDismissed) {
              console.log('Action canceled');
            }
          });
        }
         else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
    
            text: response.data.message,
          
          });
        }
        
        
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { error: errorCode, message } = error.response.data;
        if (errorCode === 1) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: message,
            // timer: 2000
          });
   
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "An unexpected error occurred.",
            timer: 2000
          });
       
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "An unexpected error occurred.",
          // timer: 2000
        });
      }
    } finally {
      setLoading(false);
    }
  };
  

  const fetchData3 = async () => {
    try {
 

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const customer_id = localStorage.getItem("cid");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("id", dataformData);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-swan-selfcompany-investment-plans`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
  if(data){
    
    console.warn("data",data)
    setFormData(data[0]);
  }
  

    } catch (error) {
  
    }
  };
  useEffect(() => {
    // setTimeout (() =>{

      fetchData3();
    // },1000)
    
  }, [dataformData]);
  const fetchData23 = async () => {
    try {
     

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const customer_id = localStorage.getItem("Addplan_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", customer_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-swan-selfcompany-customers`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
   
  
      setBusiness(data);
     
     
    } catch (error) {
      console.error("Error fetching data:", error);
     
    }
  };
  useEffect(() => {
    fetchData23();
  }, []);
  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-6">
            
              <h3 className="mb-0">Customer Investment Plan </h3>
            </div>
            <div className="col-lg-4 text-end">
             
               {businessss &&
            businessss.map((business) => (<h3 className="text-success"><MdOutlineAccountBalanceWallet/>{business.user_credit}</h3>))}
            </div>
            <div className="col-lg-2 text-end">
             
              <Link to="/Customermanagement" className="mx-1">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Container fluid>
            <Row>
            <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="invest_plain_id">
                    Invest Plan Name <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${errors.invest_plain_id ? 'input-error' : ''}`}
                    id="invest_plain_id"
                    name="invest_plain_id"
                    value={formData11.invest_plain_id}
                    onChange={handleInputChange11}
                  >
                    <option value="">Select Invest Plan</option>
                    {dataplan.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                 
                </div>
              </div>
            
            {dataformData &&
            <>
                <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="minimum_investment">
                    Minimum Investment <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.minimum_investment ? "input-error" : ""
                    }`}
                    id="minimum_investment"
                    name="minimum_investment"
                    type="text"
                    value={formData.minimum_investment}
                    // onChange={handleInputChange}
                  disabled
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="interest_rate">
                    Interest Rate (CAGR)<span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.interest_rate ? "input-error" : ""
                    }`}
                    id="interest_rate"
                    name="interest_rate"
                    type="text"
                    value={formData.interest_rate}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
  <div className="form-group local-forms">
    <label className="form-label" htmlFor="is_compound">
      Select Compound <span className="login-danger">*</span>
    </label>
    <select
      className={`form-select ${
        errors.is_compound ? "input-error" : ""
      }`}
      id="is_compound"
      name="is_compound"
      value={formData.is_compound}
      onChange={handleInputChange}
    >
      <option value=" ">Select</option>
      <option value="0">Not Compound Interest</option>
      <option value="1">Compound Interest</option>
    </select>
  </div>
</div>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="interest_rate">
                    Plan Duration <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.plan_duration ? "input-error" : ""
                    }`}
                    id="plan_duration"
                    name="plan_duration"
                    value={formData.plan_duration}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="plan_duration_type">
                    Plan Duration Type <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.plan_duration_type ? "input-error" : ""
                    }`}
                    id="plan_duration_type"
                    name="plan_duration_type"
                    value={formData.plan_duration_type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Type</option>
                    {datatype.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>  
               <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms ">
                  <label className="form-label" htmlFor="invested_amount">
                    Invested Amount <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${errors.invested_amount ? 'input-error' : ''}`}
                    id="invested_amount"
                    name="invested_amount"
                    type="text"
                    value={formData11.invested_amount}
                    onChange={handleInputChange11}
                  />
              
                </div>
              </div>
              </>

               }
                
              {/* <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="plan_duration">
                    Plan Duration <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${errors.plan_duration ? 'input-error' : ''}`}
                    id="plan_duration"
                    name="plan_duration"
                    value={formData.plan_duration}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Plan Duration</option>
                    {data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
               
                </div>
              </div> */}
              {/* <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="plan_duration_type">
                    Plan Duration Type <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${errors.plan_duration_type ? 'input-error' : ''}`}
                    id="plan_duration_type"
                    name="plan_duration_type"
                    value={formData.plan_duration_type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Plan Duration Type</option>
                    {datatype.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                 
                </div>
              </div> */}
              {/* <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="company_id">
                    Company Name <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${errors.company_id ? 'input-error' : ''}`}
                    id="company_id"
                    name="company_id"
                    value={formData.company_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Company</option>
                    {datacom.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                 
                </div>
              </div> */}
           
              {/* <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="Plan_image">
                    Plan Image
                  </label>
                  <input
                    className="form-control"
                    id="Plan_image"
                    name="Plan_image"
                    type="file"
                    onChange={handleImageChange}
                  />
                  {fileimage && <img src={fileimage} alt="Preview" />}
                </div>
              </div> */}
              <Col md={12}>
                <div className="mb-3 d-flex justify-content-end">
                  <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="btn btn-sm btn-primary"
                    type="submit"
                  >
                    Submit
                    {loading && (
                      <Spinner
                        animation="border"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </button>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col className="text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
                </button>
              </Col>
            </Row> */}
          </Container>
    
        </div>
      </div>
    </div>
  );
}
