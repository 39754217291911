import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { Link } from "react-router-dom";
export default function Investmentedit() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const investmentedit_id = localStorage.getItem("InvestUpdate_id");
  const InvestUpdateplan_id = localStorage.getItem("InvestUpdateplan_id");
  const customer_id = localStorage.getItem("customer_id");
  const [data, setData1] = useState([]);
  const [Data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    plan_duration: "",
    interest_rate: "",
    invested_amount: "",
    is_compound: "",
    invest_plain_id: "",
    plan_duration_to: "",
    plan_duration_from: "",
    is_expired: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchDataa();
  }, []);
  const fetchDataa = async () => {
    const token = localStorage.getItem("bear_token");
    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-paln-duration`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;
      const transformedData = rawData.map((item) => ({
        id: item,
        name: `${item}`,
      }));
      setData1(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", customer_id);
      formData.append("invest_id", investmentedit_id);
      formData.append("invest_plain_id", InvestUpdateplan_id);

      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-swan-selfcompany-customers-investment-running-plan`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      const dataurl = response.data.url;

      console.warn("ddd", data);
      if(data && data[0]){

        setFormData(data[0]);
      }
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "invested_amount" && (value.length > 20 || isNaN(value))) {
      return;
    }
    if (name === "interest_rate" && (value.length > 8 || isNaN(value))) {
      return;
    }
    if (name === "name") {
      formattedValue = value.replace(/[^A-Za-z]/g, "").slice(0, 40);
    }

    setFormData({ ...formData, [name]: formattedValue });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  const validateForm = () => {
    const {
      invested_amount,
      plan_duration,
      plan_duration_type,
      interest_rate,
    } = formData;
    const isEmpty = (value) =>
      !value || (typeof value === "string" && !value.trim());

    if (isEmpty(invested_amount)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Minimum Investment is required",
       
      });
      return false;
    }
    if (isEmpty(plan_duration)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Plan Duration is required",
  
      });
      return false;
    }
    if (isEmpty(plan_duration_type)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Plan Duration Type is required",
     
      });
      return false;
    }
    if (isEmpty(interest_rate)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Interest Rate is required",
     
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const requiredFields = [];

    // if (hasError) {
    //   setLoading(false);
    //   return;
    // }
    // let validationErrors = {};
    // let hasError = false;

    // for (const { field, name } of requiredFields) {
    //   if (!formData[field]) {
    //     validationErrors[field] = `Please fill in the ${name}.`;
    //     hasError = true;
    //   }
    // }

    // setErrors(validationErrors);

    // if (hasError) {
    //   setLoading(false);
    //   return;
    // }
    const confirmResult = await Swal.fire({
      title: 'Do you want to update the investment plan details?',
      text: "Ensure all the details are correct before proceeding.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update!',
      cancelButtonText: 'Cancel',
    });
  
    if (!confirmResult.isConfirmed) {
      setLoading(false);
      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'The investment plan update was cancelled.',
      });
      return;
    }
    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();

      updatedFormData.append("user_id", broker_id);
      updatedFormData.append("cust_id", customer_id);
      updatedFormData.append("invest_id", investmentedit_id);
      updatedFormData.append("invest_plain_id", InvestUpdateplan_id);
      updatedFormData.append("plan_duration", formData.plan_duration);
      updatedFormData.append("interest_rate", formData.interest_rate);
      updatedFormData.append("invested_amount", formData.invested_amount);
      updatedFormData.append("is_compound", formData.is_compound);
      updatedFormData.append("is_expired", formData.is_expired);
      updatedFormData.append("plan_duration_to", formData.plan_duration_to);
      updatedFormData.append("plan_duration_from", formData.plan_duration_from);
      updatedFormData.append("company_id", 1);
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}update-swan-selfcompany-customers-investment-running-plan`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);
      const data = response.data;

      if (data.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
       
        });

        setTimeout(() => {
          navigate("/Custumerdetails");
        }, 2000);
      } else if (response.data.validstatus === "0") {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
            showCancelButton: true,
            confirmButtonText: 'Go to Customer Plan',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn-greenes',
              cancelButton: 'btn-reded',
          },
        }).then((result) => {
            if (result.isConfirmed) {
                navigate("/Single-Customer-Plan");
            }
        });
    } else if (response.data.lowbalance === "0") {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
            showCancelButton: true,
            confirmButtonText: 'Add Transaction',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn-greenes',
              cancelButton: 'btn-reded',
          },
        }).then((result) => {
            if (result.isConfirmed) {
                navigate("/Custumerdetails");
            }
        });
    }else{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
         
        });
      }
    } catch (error) {
      console.error("Error updating user broker:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchData23 = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const customer_id = localStorage.getItem("customer_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", customer_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-swan-selfcompany-customers`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
    
      // console.warn("Ddd", data11);
      // setBusiness(data11);
   
      // Set fetched data
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData23();
  }, []);
  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="mb-0">Update Investment Plan</h3>
            </div>
            {Data && Data.map((daata) => {
  return (
    <div className="col-lg-3 text-end" key={daata.id}>
     <h3 className="text-success"><MdOutlineAccountBalanceWallet className="fs-4"/>{daata.user_credit} </h3> 
    </div>
  );
})}
            <div className="col-lg-2 text-end">
              <Link to="/Custumerdetails" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form>
        <div className="card">
          <div className="card-body">
            <Container fluid>
              <Row>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="invested_amount">
                      Invested Amount <span className="login-danger"></span>
                    </label>
                    <input
                      className={`form-control `}
                      id="invested_amount"
                      name="invested_amount"
                      type="text"
                      value={formData.invested_amount}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="interest_rate">
                      Interest Rate <span className="login-danger"></span>
                    </label>
                    <input
                      className={`form-control `}
                      id="interest_rate"
                      name="interest_rate"
                      type="text"
                      value={formData.interest_rate}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="is_compound">
                      Select Compound <span className="login-danger">*</span>
                    </label>
                    <select
                      className={`form-select ${
                        errors.is_compound ? "input-error" : ""
                      }`}
                      id="is_compound"
                      name="is_compound"
                      value={formData.is_compound}
                      onChange={handleInputChange}
                    >
                      <option value=" ">Select</option>
                      <option value="0">Not Compound Interest</option>
                      <option value="1">Compound Interest</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="interest_rate">
                      Plan Duration <span className="login-danger"></span>
                    </label>
                    <select
                      className={`form-select ${
                        errors.plan_duration ? "input-error" : ""
                      }`}
                      id="plan_duration"
                      name="plan_duration"
                      value={formData.plan_duration}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      {data.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <Col md={12}>
                  <div className="mb-3 d-flex justify-content-end">
                    <button
                      onClick={handleSubmit}
                      // disabled={loading}
                      className="btn btn-sm btn-primary"
                      type="submit"
                    >
                      Update
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </form>
    </div>
  );
}
