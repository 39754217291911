import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";

export default function AddMoneyTransaction() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const customer_idTransaction = localStorage.getItem("customer_idTransaction");
  const customer_id = localStorage.getItem("customer_id");
  const cid = localStorage.getItem("cid");
  const c_id = localStorage.getItem("c_id");

  const [transactionAmount, setTransactionAmount] = useState("");
  const [remark, setremark] = useState("");
  const [Data, setData] = useState();

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOnline, setIsOnline] = useState(true);


  const [AllEmployee, setAllEmployee] = useState(''); 
  const fetchData = async () => {
    try {
      // setLoading(true);
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-partner-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const totalEmp = response.data.overAllEarnAmout;
      // alert(totalEmp);
      setAllEmployee(totalEmp)
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);








  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
   
    if (name === "transaction_amount") {
      // Allow only numeric input, remove leading zeros, and restrict to 12 digits
      if (/^[0-9]*$/.test(value) && value.length <= 12) {
        formattedValue = value.replace(/^0+/, ""); // Remove leading zeros
      } else {
        return; // Do not update state for invalid input
      }
    } else if (name === "transaction_id") {
      formattedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 20);
    } 
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    if (name === "transaction_amount") {
      setTransactionAmount(formattedValue);
    } 
  };

  // Payment Mode Handler
  const handleRemark = (e) => {
    setremark(e.target.value);
  };

  const handleValidation = () => {
    let validationErrors = {};
    let hasError = false;

    if (!transactionAmount) {
      validationErrors.transaction_amount = "Transaction amount is required";
      hasError = true;
    } else if (isNaN(transactionAmount) || transactionAmount <= 0 || transactionAmount.length > 12) {
      validationErrors.transaction_amount = "Transaction amount must be a positive number and not exceed 12 digits";
      hasError = true;
    }
    setErrors(validationErrors);
    return !hasError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("bear_token");

    if (!handleValidation()) {
      return;
    }

    setIsSubmitting(true);

    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to add this money transaction?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, add Money!',
      cancelButtonText: 'Cancel',
    });
  
    if (!confirmResult.isConfirmed) {
      setIsSubmitting(false);
      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'The money transaction was cancelled.',
      });
      return;
    }
    const dataUpdate = new FormData();
    dataUpdate.append("transaction_amount", transactionAmount);
    // dataUpdate.append("remark", remark);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}swan-selfcompany-use-self-balance-for-investment`,
        dataUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Customer Transaction Added Successfully",
        });
        setTimeout(() => {
            window.location.href = "/AddInSwanTransaction";
    }, 2000);
       
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        });
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });

      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="mb-0">Invest Earn Amount in Swan Investment Company{" "}
                {/* <span className="capitalize ">( {Data} )</span> */}
                </h3>
            </div>

 
    <div className="col-lg-4 text-end" >
           
              <h3 className="text-success"><MdOutlineAccountBalanceWallet className="fs-4"/>{AllEmployee || 0} </h3> 
  
    </div>

 <div className="col-lg-2 text-end">
              <Link to="/AddInSwanTransaction" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-12">
            <div className="form-group local-forms d-flex align-items-center">
              <label htmlFor="transaction_amount" className="w-25">Transaction Amount <span className="text-danger">*</span> : </label>
              <input
                type="text"
                className={`form-control w-75 ${errors.transaction_amount ? "is-invalid" : ""}`}
                id="transaction_amount"
                name="transaction_amount"
                value={transactionAmount}
                onChange={handleInputChange}
              />
            
            </div>
            </div>

              <div className="col-md-12">
                     <div className="form-group local-forms d-flex align-items-center">
                <label htmlFor="remark" className="w-25">Remark <span className="text-danger"></span> : </label>
                <input
                  type="text"
                  className={`form-control w-75`}
                  id="remark"
                  name="remark"
                  value={remark}
                  onChange={handleRemark}
                />
              
              </div>
              </div>
        
            <div className="col-md-12 text-end">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
