import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdPersonAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaSearch } from "react-icons/fa";
import company from "../../assets/img/icons/building.png";
import { MdCurrencyRupee } from "react-icons/md";
import { FaRupeeSign } from "react-icons/fa";
import Swal from 'sweetalert2';
import { FaFileContract } from 'react-icons/fa'; 
import { GiReceiveMoney, GiTakeMyMoney, GiReturnArrow } from "react-icons/gi";
import { FaHandHoldingHand } from "react-icons/fa6";
export default function Customermanagement() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); 
  const navigate = useNavigate();
  const [AllEmployee, setAllEmployee] = useState(''); 
  const [ActiveEmployee, setActiveEmployee] = useState(''); 
  const [InactiveEmployee, setInactiveEmployee] = useState(''); 
  const [InactiveEmployee2, setInactiveEmployee2] = useState(''); 
  const [overAllEarnAmout, setoverAllEarnAmout] = useState(''); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const company_id = localStorage.getItem("company_id");
  const company_Name = localStorage.getItem("company_Name");
  const broker_id = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");

  const fetchData = async () => {
    try {
      setLoading(true);


      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-partner-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;

     
      const totalEmp = response.data.totalCompanyEarnAmount;
      const ActiveEmployee = response.data.totalCustomers;
      const InactiveEmployee = response.data.totalCompanyPartners;
      const InactiveEmployee2 = response.data.totalPartnersTransactions;

      setAllEmployee(totalEmp)
      setActiveEmployee(ActiveEmployee)
      setInactiveEmployee(InactiveEmployee)
      setInactiveEmployee2(InactiveEmployee2)
      setoverAllEarnAmout(response.data.overAllEarnAmout)
      setbaseurl(data1);
      // Set fetched data
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleClickadd = () => {
    navigate("/AddPartner");
 
  };
  const handleEditCustomerClick = (cust_id) => {
    localStorage.setItem('Partner_id', cust_id);
    navigate("/Partner-Update");
  };


  const handleviveCustomerClick = (cust_id) => {
    localStorage.setItem('Partner_id', cust_id);
    navigate("/Partner-Details");
  };
  


  const filteredData = data
  ? data.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  : [];
   

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [isActive, setIsActive] = useState(false);


  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:                       
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Partner Analytics</h2>
            </div>
            <div className="col-lg-4 text-end">
              {/* <Link to="/Home" className="">
                <button className="btn btn-success">Back</button>
              </Link> */}
            </div>
          </div>
        </div>

      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-3 col-xxl-3">
     
          <div className="card h-md-100 ecommerce-card-min-width">
          <Link
            to="/Company-Earn"
            className="linkWithoutUnderline "
          >
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
                Total Company Earn Amount
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <GiTakeMyMoney className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                    {overAllEarnAmout}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
            </Link>
          </div>
       
        </div>
        <div className="col-md-3 col-xxl-3">
   
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Customer</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {ActiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
       
        </div>
        <div className="col-md-3 col-xxl-3">
   

          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Company Parnter</h5> 
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  
              
                  <FaHandHoldingHand className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {InactiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
       
        </div>
        <div className="col-md-3 col-xxl-3">
   
   <div className="card h-md-100">
     <div className="card-header pb-0">
       <h5 className="mb-0 mt-2"> Partner Transaction</h5>
     </div>
     <div className="card-body d-flex flex-column justify-content-end">
       <div className="row justify-content-between">
         <div className="col pe-0">
           <GiTakeMyMoney className="fs-4 text-success" />
         </div>
         <div className="col ps-0">
           <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
             <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
               {InactiveEmployee2}
             </p>
           </div>
         </div>
         <div className="col-auto ps-0 mt-n4">
           <div
             className="echart-default-total-order"
             data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
             data-echart-responsive="true"
           />
         </div>
       </div>
     </div>
   </div>

 </div>
      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Partner List</h3>
            </div>
            <div className="col md-4 d-flex justify-content-end border-0 shadow-none">
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={()=>handleClickadd()}
              >
              <IoMdAdd className="me-1 fs--2" />     Add Partner
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card"
        id="ticketsTable"
        data-list='{"valueNames":["client","subject","status","priority","agent"],"page":11,"pagination":true,"fallback":"tickets-table-fallback"}'
      >
        <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by name"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>
        {loading && (
          <div id="preloader">
            <div id="loading-center">
              <div className="loader">
                <div className="waviy">
                  <img
                    src="https://swaninvestment.in/frontant/assets/img/loader.png"
                    alt="Loader image"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm mb-0 fs-10 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle">Sr.</th>
                  <th className="text-800 sort align-middle">Name</th>
                  <th className="text-800 sort align-middle">Email</th>
                  <th className="text-800 sort align-middle">Phone</th>
                  <th className="text-800 sort align-middle">Total Fund</th>
                  <th className="text-800 sort align-middle">Parntership</th>
                  <th className="text-800 sort align-middle">Joining Date</th>
               
                  <th className="text-800 sort align-middle">Action</th>
                </tr>
              </thead>
              <tbody className="list " id="table-ticket-body">
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr>
                      <td>{startIndex + index + 1}</td>
                      <td className="capitalize">{item.name}</td>
                      <td>
                        <a href="mailto:{item.email}">{item.email}</a>
                      </td>
                      <td>
                      <a href={`tel:${item.phone}`}>{item.phone}</a>
                  </td>

                      <td className="capitalize"> <MdCurrencyRupee /> {item.user_credit}</td>
                      <td className="capitalize"> {item.partnership} %</td>
                      <td className="capitalize"> {item.joining_date} </td>
                     
                      <td>
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                            key="Edit"
                            placement="top"
                            overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                          >
                            <button
                              onClick={() => handleEditCustomerClick(item.cust_id)}
                              style={{ "--i": "#2c7be5" }}
                            >
                              {" "}
                              <FaRegEdit className="color_link" />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              onClick={() => handleviveCustomerClick(item.cust_id)}
                              style={{ "--i": "#27bcfd" }}
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                        
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>

      </div>
   
    </>
  );
}
