import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaFilePdf, FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { MdCurrencyRupee } from "react-icons/md";
import { IoPersonSharp } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoReloadSharp } from "react-icons/io5";
import { parse, isWithinInterval } from "date-fns";
import {
  MdDelete,
  MdOutlinePendingActions,
  MdOutlineSmsFailed,
  MdArrowOutward,
} from "react-icons/md";

import { IoMdAdd, IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Button, Row } from "react-bootstrap";
import Swal from "sweetalert2";

import { GrTransaction } from "react-icons/gr";
import { FaDiagramSuccessor } from "react-icons/fa6";
import pdf from "./../../assets/img/pdf.svg";

export default function TransactionDetails() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [baseurl, setbaseurl] = useState();
  const [baseurlprofil, setbaseurlprofil] = useState();
  const [profilimage, setprofilimage] = useState();
  const [CustomerName, setCustomerName] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [AllEmployee, setAllEmployee] = useState("");
  const [ActiveEmployee, setActiveEmployee] = useState("");
  const [PendingEmployee, setPendingEmployee] = useState("");
  const [user_credit, setuser_credit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const emp_id = localStorage.getItem("employee_id");
  const userid = localStorage.getItem("userid");
  const token = localStorage.getItem("bear_token");
  const broker_id = localStorage.getItem("broker_id");
  const customer_id = localStorage.getItem("customer_id");
  const [fromDate, setFromDate] = useState(null); // Date object
  const [toDate, setToDate] = useState(null);
  const [allData, setAllData] = useState();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", customer_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}swan-selfcompany-narmalCustomer-Debit-transactions-lists`,
        // `${process.env.REACT_APP_API_URL}swan-selfcompany-narmalCustomer-transactions-lists`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const totalEmp = response.data.TotalcustomercrediTransaction;
      const ActiveEmployee = response.data.TotalcustomerDebitTransaction;
      const InactiveEmployee = response.data.TotalcustomerTransaction;
      
      setbaseurl(response.data.trnximageUrl);
      setbaseurlprofil(response.data.profileImage);
      setprofilimage(response.data.image);
      
      setCustomerName(response.data.CustomerName);
      setAllEmployee(totalEmp);
      setActiveEmployee(ActiveEmployee);
      setPendingEmployee(InactiveEmployee);
      setAllData(response.data.data);
      setData(response.data.data);
      const Inactiveuser_credit = response.data.userCreditBal.user_credit; 
      setuser_credit(Inactiveuser_credit);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  const getFilteredTransactions = async (fromDate, toDate) => {
    if (data && data.length == 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Sorry, no data found!",
        // timer: 2000
      });
      return;
    }
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please choose a valid date!",
        // timer: 2000
      });
      setLoading(false);
      return;
    }

    // Convert fromDate and toDate to YYYY-MM-DD format
    const formattedFromDate = new Date(fromDate).toISOString().split("T")[0];
    const formattedToDate = new Date(toDate).toISOString().split("T")[0];
    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}
        swan-selfcompany-narmalCustomer-FilterByDate-transactions?from_date=${formattedFromDate}&to_date=${formattedToDate}&smalladmins_id=${broker_id}&cust_id=${customer_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === 0) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Sorry, no data found!",
          // timer: 2000
        });
      }
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching filtered transactions:", error);
    }
  };

  const downloadFile = async (fromDate, toDate) => {
    if (data && data.length == 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Sorry, no data found!",
        // timer: 2000
      });
      return;
    }
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please choose a valid date!",
        // timer: 2000
      });
      setLoading(false);
      return;
    }

    // Convert fromDate and toDate to YYYY-MM-DD format
    const formattedFromDate = new Date(fromDate).toISOString().split("T")[0];
    const formattedToDate = new Date(toDate).toISOString().split("T")[0];

    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}swan-selfcompany-narmalCustomer-FilterByDate-transactions-statement-PDF?from_date=${formattedFromDate}&to_date=${formattedToDate}&smalladmins_id=${broker_id}&cust_id=${customer_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/pdf",
          },
          responseType: "blob",
        }
      );

      if (response.headers["content-type"] === "application/pdf") {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Normal_Customer_Filter_Transactions.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Response is not a PDF file");
      }
    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };

  const handleDownload = () => {
    downloadFile(fromDate, toDate);
  };

  const parseDateString = (dateString) => {
    return parse(dateString, "dd-MM-yyyy", new Date());
  };
  const handleSearch = () => {
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please choose a valid date!",
        // timer: 2000
      });
    }
    console.warn("allData",allData)
    if (fromDate && toDate) {
      const filteredTransactions = allData.filter((item) => {
        const transactionDate = parseDateString(item.transaction_date);
        return transactionDate >= fromDate && transactionDate <= toDate;
      });
      setData(filteredTransactions);
    } else {
      setData(allData);
    }
  };
  const handleClick = (id) => {
    localStorage.setItem("Addplan_id", id);
    navigate("/Add-More-plan");
  };

  const handleClickAdd = (id) => {
    navigate("/Add-admins-customer");
  };

  const handleEditCustomerClick = (cust_id) => {
    localStorage.setItem("customer_id", cust_id);
    navigate("/Custumerdetailsedit");
  };

  const handleRefresh = () => {
    setFromDate("");
    setToDate("");
    setSearchTerm("");
    getProfile();
  };

  const handleViewCustomerClick = (customer_id) => {
    localStorage.setItem("customer_idTransaction", customer_id);
    navigate("/Add-Normal-Transaction");
  };

  // const filteredData = data.filter(
  //   (item) =>
  //     item.transaction_id &&
  //     item.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const filteredData = data
    ? data.filter(
        (item) =>
          item.transaction_id &&
          item.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleRefreh = () => {
    window.location.reload();
  };
  const handleviveCustomerClick = (transactionid) => {
    localStorage.setItem("transactionid", transactionid);
    navigate("/Normal-Transaction-View");
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Customer Transactions Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Custumerdetails" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
          <Link
            to="/Customer-Transaction-Details"
            className="linkWithoutUnderline "
          >
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">
                  Total Transactions
                </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GrTransaction className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {PendingEmployee}
                    </p>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
          <Link
            to="/Customer-Transactiondetails-credit"
            className="linkWithoutUnderline "
          >
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">
                  Total Credit
                </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GrTransaction className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {AllEmployee}
                    </p>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
          <Link
            to="/Customer-Transactiondetails-debit"
            className="linkWithoutUnderline active"
          >
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Total Debit</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GrTransaction className="fs-4 text-info" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-info">
                        {ActiveEmployee}
                      </p>
                    </div>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="card mb-3">
        <div className="bg-holder d-none d-lg-block bg-card" />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-7 d-flex align-items-center">
              <h5 className="mb-0 capitalize d-flex align-items-center">
                {profilimage == null ? (
                  <>
                    <IoPersonSharp className="fs-6 text-dark me-1" />
                  </>
                ) : (
                  <img
                    src={`${baseurlprofil}/${profilimage}`}
                    height={35}
                    width={35}
                    alt="plan_image"
                    className="img-fluid rounded-circle fs-6  me-1"
                  />
                )}{" "}
                {CustomerName}
              </h5>
            </div>
            <div className="col-lg-2 d-flex align-items-center">
              <h5 className="mb-0 capitalize d-flex align-items-center text-success">
                <MdOutlineAccountBalanceWallet className="fs-4 " />{" "}
                {user_credit}
              </h5>
            </div>
            <div className="col-lg-3 d-flex justify-content-end text-end">
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    <small>Add Customer Transaction</small>
                  </Tooltip>
                }
              >
                <button
                  className="btn  text-light bg-success"
                  onClick={() => {
                    handleViewCustomerClick(customer_id);
                  }}
                >
                  <IoMdAdd className="me-1 fs--2" /> Add Transaction
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Transaction Id"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-9 text-end">
              <Row>
                <div className="col-md-4 form_details">
                  <div className="form-group local-forms mb-0">
                    <label className="form-label">From Date</label>

                    <DatePicker
                      selected={fromDate}
                      className="form-control"
                      onChange={(date) => setFromDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD-MM-YYYY"
                    />
                  </div>
                </div>
                <div className="col-md-4 form_details">
                  <div className="form-group local-forms mb-0">
                    <label className="form-label">To Date</label>

                    <DatePicker
                      selected={toDate}
                      className="form-control"
                      onChange={(date) => setToDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD-MM-YYYY"
                    />
                  </div>
                </div>

                <div className="col-md-4 d-flex gap-1 justify-content-evenly align-items-center">
                  <button className="btn btn-primary" onClick={handleSearch}>
                    Search
                  </button>
                  <button className="btn btn-dark" onClick={handleRefresh}>
                    Refresh
                  </button>
                  <button className="btn btn-dark btn-sm" onClick={handleDownload}>
                    <FaCloudDownloadAlt className="fs-6 text-light" />
                  </button>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr. No.</th>

                  <th>Transaction Id</th>
                  <th>Payment Type</th>
                  <th>Payment Mode</th>
                  <th>Payment Mode Type</th>
                  <th>Amount</th>

                  <th>Date </th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData &&
                  paginatedData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1 + startIndex}</td>

                      <td>{item.transaction_id || "NA"}</td>

                      {/* <td className="text-center" style={{ padding: "10px" }}>
                        <div>
                          <img className="skale_img"
                            src={
                              `${baseurl}/${item.trnx_image}`      
                            }
                          />

                        </div>
                      </td> */}

                      <td className="capitalize ">
                        {" "}
                        <span
                          className={`badge bg-${
                            item.payment_type === "debit"
                              ? "primary"
                              : item.payment_type === "credit"
                              ? "success"
                              : "secondary"
                          }`}
                        >
                          {item.payment_type || "NA"}
                        </span>
                      </td>

                      <td className="capitalize">
                        {item.payment_mode || "NA"}
                      </td>
                      <td className="capitalizeall">
                        {item.payment_mode_type || "NA"}
                      </td>
                      <td>{item.transaction_amount}</td>

                      <td><div>{item.transaction_date} </div><div>{item.time}</div></td>
                      <td>
                        <span
                          className={`badge bg-${
                            item.transaction_status === "success"
                              ? "success"
                              : item.transaction_status === "pending"
                              ? "warning"
                              : "danger"
                          }`}
                        >
                          {item.transaction_status || "NA"}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              onClick={() => handleviveCustomerClick(item.id)}
                              className="bg-success"
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div>
              Page {currentPage} of {totalPages}
            </div>
            <div className="d-flex gap-2">
              <div>
                <button
                  className="border-0 "
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <IoIosArrowBack />
                </button>
              </div>
              <div className="pagecurrent">{currentPage}</div>
              <div>
                <button
                  className="border-0 "
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <IoIosArrowForward />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
