


import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';
import { GiReceiveMoney } from "react-icons/gi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import { FaHistory } from "react-icons/fa";
export default function Investmentinsawn() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);


const [AllEmployee, setAllEmployee] = useState(0); 
const [ActiveEmployee, setActiveEmployee] = useState(0); 
const [InactiveEmployee, setInactiveEmployee] = useState(0)


  const navigate = useNavigate();
  const custumerdetails_id = localStorage.getItem("custumerdetails");

  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", '1');
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-users-investment-history-logs`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;
      const totalEmp = response.data.TotalHistoryLogs;
      const ActiveEmployee = response.data.TodaysHistoryLogs;
      // alert(ActiveEmployee)
      const InactiveEmployee = response.data.LastHistoryLogs;
    
      setAllEmployee(totalEmp)
      setActiveEmployee(ActiveEmployee)
      setInactiveEmployee(InactiveEmployee)

      setbaseurl(data1)
      setData(data);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Delete = async (id) => {
    const token = localStorage.getItem("bear_token");
    const broker_id = localStorage.getItem("broker_id");
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
      if (result.isConfirmed) {
        setLoading(true);
        const formData = new FormData();
        formData.append("user_id", broker_id);
        formData.append("id", id);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}delete-swan-selfcompany-investment-plans`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          },
          data: formData
        };

        const response = await axios(config);
        const data = response.data.status;
        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            timer: 1500
          });
    
          setLoading(false);
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            timer: 2000
          });
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const handleStatusChange = async (business_id, currentStatus) => {
    const token = localStorage.getItem("bear_token");
    const broker_id = localStorage.getItem("broker_id");
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("user_id", broker_id);
        formData.append("id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1); 

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}swan-selfcompany-investment-plans-updateststus`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            timer: 1500
          });
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            timer: 2000
          });
        
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error changing the status.',
        timer: 2000
      });
      setLoading(false);
    }
  };
  const filteredData = data ? data.filter(item =>
    item.plan_name.toLowerCase().includes(searchTerm.toLowerCase())
  ):[];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleClick = (id) => {
    localStorage.setItem('custumerdetails', id);
    navigate('/Custumerdetails');
  }

  const handleClickadd = () => {
    navigate('/Agreement');
  }

  const handleClickinvestmentId = (id) => {
    localStorage.setItem('investment_id', id);
    navigate('/InvestmentView');
  }

  const handleClickinvestmenteditId = (id) => {
    localStorage.setItem('investment_id', id);
    navigate('/Investmentedit');
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }




  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
             <h3 className="mb-0">History Logs Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Custumerdetails" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>

      </div>
   
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
              
            Total Histories

              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <FaHistory className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                    {AllEmployee}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div className="col-md-4 col-xxl-3">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Todays Histories</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <FaHistory className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {ActiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xxl-3">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2">No Histories
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <FaHistory className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {InactiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
             <h3 className="mb-0">History Logs List</h3>
            </div>
            {/* <div className="col-lg-4 text-end">
            <button type="button" className="btn btn-sm btn-success" onClick={handleClickadd}>
                <IoMdAdd className="me-1 fs--2" />History Logs
              </button>
            </div> */}
          </div>
        </div>

      </div> 

      <div className="card mb-3">
        <div className="card-header">
      <div className="card-header w-100 border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <div className="justify-content-end mt-1 d-flex w-100">
              <form>
                <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm"
                  type="text"
                  placeholder="Search by Plan Name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
         <table className="table table-sm mb-0 fs--1">
            <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle" >
                    Sr.
                  </th>
                  <th className="text-800 sort align-middle">Name</th>
                  <th className="text-800 sort align-middle">Company Name</th>
                  <th className="text-800 sort align-middle">Interest Rate</th>
                  <th className="text-800 sort align-middle">Duration</th>
                  <th className="text-800 sort align-middle">Before Amount</th>
                  <th className="text-800 sort align-middle">After Amount</th>
                  <th className="text-800 sort align-middle">Duration To</th>
                  <th className="text-800 sort align-middle">Duration From</th>
                  <th className="text-800 sort align-middle">Expired</th>
                </tr>
              </thead>
              <tbody className="list capitalize small-font">
                {loading ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : currentData.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No records found
                    </td>
                  </tr>
                ) : (
                  currentData.map((item, index) => (
                
                      <tr >
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>{item.plan_name}</td>
                      <td>{item.company_name}</td>
                      <td>{item.interest_rate}%</td>
                  
                      <td>{item.plan_duration}  {item.plan_duration_type}</td>
                      <td>{item.invested_amount}  </td>
                      <td>{item.after_balance}  </td>
                      <td>{item.plan_duration_to}  </td>
                      <td>{item.plan_duration_from }  </td>
                      <td>
  <span className={`badge ${item.is_expired === 'active' ? 'bg-success' : 'bg-danger'}`}>
    {item.is_expired === 'active' ? 'Active' : 'Expired'}
  </span>
</td>

                 
                    
                   
                    </tr>
                  ))
                )}
              </tbody>
            </table> 
          
      <div className=" mt-3 mb-3">
      <button
                className="btn btn-sm btn-light"
                disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}
              >
                <IoIosArrowBack />
              </button>
     
        <span className="align-self-center">Page {currentPage} of {totalPages}</span>
       
        <button
                className="btn btn-sm btn-light"
                disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}
              >
                <IoIosArrowForward />
              </button>
      </div>
          </div>
        </div>
      </div>
    </>
  );
}
