import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { FaCoins, FaDollarSign, FaChartLine, FaUsers } from 'react-icons/fa'; 
import { FaFilePdf } from "react-icons/fa6";
import company from "../../assets/img/icons/building.png";
import { MdBusinessCenter } from "react-icons/md";
import { GiReceiveMoney, GiTakeMyMoney, GiReturnArrow } from "react-icons/gi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
export default function Companymangement() {

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [overallTransactionSum, setoverallTransactionSum] = useState();
  const [totalCreditfund, settotalCreditfund] = useState(0);
  const [overAllEarnAmout, setoverAllEarnAmout] = useState(0);
  const [ReturnPL, setReturnPL] = useState();
  const [totalPL, settotalPL] = useState();
  const [totalCustomer, settotalCustomer] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const cust_idPlanView = localStorage.getItem("cust_idPlanView");
  const invest_plain_idPlanView = localStorage.getItem(
    "invest_plain_idPlanView"
  );
  const invest_idPlanView = localStorage.getItem("invest_idPlanView");
  const broker_id = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");

  const fetchData = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("company_id", 1);
      formData.append("invest_plain_id", invest_plain_idPlanView);
      formData.append("cust_id", cust_idPlanView);
      formData.append("invest_id", invest_idPlanView);
  
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-customers-company-earns-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setData(response.data.data);
      setbaseurl(response.data.image_url);
      setoverAllEarnAmout(response.data.overAllEarnAmout)
      setoverallTransactionSum(response.data.overAllCompanyFund)
      settotalCreditfund(response.data.overAllCompanyReturnPL)
      setReturnPL(response.data.overAllCustomerReturnPL)
      settotalPL(response.data.overAllCustomerFund)
      settotalCustomer(response.data.TotalCustomers)
     
  
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

  }, []);


  const filteredData = data
    ? data.filter((item) =>
      item.cust_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const GenerateBill = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Want to Generate Customer Bill!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Generate Bill!",
      });
  
      if (result.isConfirmed) {
        let progressValue = 0;
        let progressInterval;
  
        // Show initial Swal with percentage progress
        Swal.fire({
          title: 'Generating PDF...',
          html: 'Progress: <strong><b>0%</b></strong>',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(); // Show loading spinner
            progressInterval = setInterval(() => {
              if (progressValue < 100) {
                progressValue++;
                Swal.getHtmlContainer().querySelector('b').textContent = `${progressValue}%`;
              }
            }, 50); // Increment every 50ms
          },
          willClose: () => {
            Swal.hideLoading(); // Ensure loader is hidden when it closes
            clearInterval(progressInterval); // Clear the interval when closing
          }
        });
  
        const formData = new FormData();
        formData.append("user_id", broker_id);
        formData.append("cust_id", id);
  
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}swan-selfcompany-generate-investment-bills-PDF`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: 'blob', // To handle binary form for file download
          data: formData,
        };
  
        const response = await axios(config);
  
        // Clear the interval and set to 100% when response is received
        clearInterval(progressInterval);
        Swal.getHtmlContainer().querySelector('b').textContent = `100%`;
  
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Investment_Bill.pdf';  // Filename for the downloaded PDF
          link.click();
  
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'PDF generated and downloaded successfully!',
            timer: 1500
          });
  
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to generate the PDF.',
            timer: 2000
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while generating the PDF.',
        timer: 2000
      });
    }
  };
  
  
  

  const handleClickCompanyId = (id) => {
    localStorage.setItem("company_id", id);
    navigate("/CompanyView");
  };





  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="mb-0">Company Earn Analytics</h3>
            </div>
            <div className="col-lg-6 text-end d-flex justify-content-end">
         
            <h3 className="mb-0 text-success">
                    {" "}
                    <MdOutlineAccountBalanceWallet className="fs-4 text-success" />{" "}
                    {overAllEarnAmout}
            </h3>

              <Link to="/investment-history-logs" className="ms-3">
                <button className="btn btn-warning  ">Investment History</button>
              </Link>      
         
              <Link to="/Companymangement" className="ms-3">
                <button className="btn btn-primary  ">Back</button>
              </Link>
            </div>
          </div>
        </div>

      </div>
      <div className="row g-3 mb-3">



<div className="col-md-3 col-xxl-3">
  <div className="card h-md-100 ecommerce-card-min-width">
    <div className="card-header pb-0">
      <h5 className="mb-0 mt-2 d-flex align-items-center">
        Over All Fund
      </h5>
    </div>
    <div className="card-body d-flex flex-column justify-content-end">
      <div className="row align-items-center">
        <div className="col pe-0">
          <FaCoins className="text-primary fa-2x " style={{width:"35px",height:"35px"}}/> {/* Blue for financial */}
        </div>
        <div className="col ps-0">
          <p className="font-sans-serif lh-1 mb-1 fs-6 text-primary number_margin">
            {overallTransactionSum}
          </p>
        </div>
        <div className="col-auto ps-0">
          <div className="echart-bar-weekly-sales h-100" />
        </div>  
      </div>
    </div>
  </div>
</div>

<div className="col-md-3 col-xxl-3">
  <div className="card h-md-100">
    <div className="card-header pb-0">
      <h5 className="mb-0 mt-2">
        Over All Return
      </h5>
    </div>
    <div className="card-body d-flex flex-column justify-content-end">
      <div className="row justify-content-between">
        <div className="col pe-0">
          <GiReceiveMoney className="text-success fa-2x" style={{width:"35px",height:"35px"}}/> {/* Green for positive values */}
        </div>
        <div className="col ps-0">
          <p className="font-sans-serif lh-1 mb-1 fs-6 text-success number_margin">
            {totalCreditfund || 0}
          </p>
        </div>
        <div className="col-auto ps-0 mt-n4">
          <div className="echart-default-total-order" />
        </div>
      </div>
    </div>
  </div>
</div>

<div className="col-md-3 col-xxl-3">
  <div className="card h-md-100">
    <div className="card-header pb-0">
      <h5 className="mb-0 mt-2">
        Over All P&L
      </h5>
    </div>
    <div className="card-body d-flex flex-column justify-content-end">
      <div className="row justify-content-between">
        <div className="col pe-0">
          <GiReceiveMoney className="text-success fa-2x" style={{width:"35px",height:"35px"}}/> {/* Red for negative values */}
        </div>
        <div className="col ps-0">
          <p className="font-sans-serif lh-1 mb-1 fs-6 text-success number_margin">
            {ReturnPL}
          </p>
        </div>
        <div className="col-auto ps-0 mt-n4">
          <div className="echart-default-total-order" />
        </div>
      </div>
    </div>
  </div>
</div>

{/* <div className="col-md-3 col-xxl-3">

    <div className="card h-md-100">
      <div className="card-header pb-0">
        <h5 className="mb-0 mt-2">
          Total Fund Of Customer
        </h5>
      </div>
      <div className="card-body d-flex flex-column justify-content-end">
        <div className="row justify-content-between">
          <div className="col pe-0">
            <GiReceiveMoney className="text-info fa-2x" style={{width:"35px",height:"35px"}}/> 
          </div>
          <div className="col ps-0">
            <p className="font-sans-serif lh-1 mb-1 fs-6 text-info number_margin">
              {totalCustomer}
            </p>
          </div>
          <div className="col-auto ps-0 mt-n4">
            <div className="echart-default-total-order" />
          </div>
        </div>
      </div>
    </div>

</div> */}
 <div className="col-md-3 col-xxl-3">

    <div className="card h-md-100">
      <div className="card-header pb-0">
        <h5 className="mb-0 mt-2">
          Over All Customer
        </h5>
      </div>
      <div className="card-body d-flex flex-column justify-content-end">
        <div className="row justify-content-between">
          <div className="col pe-0">
            <FaUsers className="text-info fa-2x" style={{width:"35px",height:"35px"}}/> 
          </div>
          <div className="col ps-0">
            <p className="font-sans-serif lh-1 mb-1 fs-6 text-info number_margin">
              {totalCustomer}
            </p>
          </div>
          <div className="col-auto ps-0 mt-n4">
            <div className="echart-default-total-order" />
          </div>
        </div>
      </div>
    </div>
 
</div> 


      </div>


      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Customer  List</h3>
            </div>
            {/* <div className="col-md-4 d-flex justify-content-end">
              <Link to="/Addcompany" className="btn btn-sm btn-success">
                <IoMdAdd className="me-1 fs--2" />   Add Company
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <div className="card" id="ticketsTable">
        <div className="card-header w-100 border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <div className="justify-content-end mt-1 d-flex w-100">
              <form>
                <div className="input-group input-search-width">
                  <input
                    className="form-control form-control-sm shadow-none search"
                    type="search"
                    placeholder="Search by name"
                    aria-label="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm mb-0 fs-10 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="fs-9 pl-0">Sr.</th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="description"
                    style={{ minWidth: "15.625rem" }}
                  >
                    Customer Name
                  </th>
                  <th className="text-800 sort align-middle" data-sort="email">
                
                    Plan Duration
                 </th>
                  <th className="text-800 sort align-middle" data-sort="email">
                
                    Invested Amount
                 </th>
                  <th className="text-800 sort align-middle" data-sort="status">
                    Invested Plans                   </th>
                  <th className="text-800 sort align-middle" data-sort="status">
               Total Return</th>
                  {/* <th className="text-800 sort align-middle" data-sort="status">
                    Status
                  </th> */}
                  {/* <th
                    className="text-800 sort align-middle"
                    data-sort="addbusiness"
                  >
                    Add
                  </th> */}
                  {/* <th
                    className="text-800 sort align-middle"
                    data-sort="addbusiness"
                  >
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody className="list">
                {loading && (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      <div id="preloader">
                        <div id="loading-center">
                          <div className="loader">
                            <div className="waviy">
                              <img
                                src="https://swaninvestment.in/frontant/assets/img/loader.png"
                                alt="Loader image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr key={index} className="">

                      <td className="align-middle client white-space-nowrap pe-3 pe-xxl-4 ps-2">
                        <div className="d-flex align-items-center gap-2 position-relative">
                          <div>
                            <span>{startIndex + index + 1}</span>
                          </div>
                        </div>
                      </td>


                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700 text-transform-capitalize">
                            {item?.cust_name ?? "No Name"}
                          </h6>
                        </div>
                      </td>

                      <td className="align-middle priority">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700">
                       
                          {item?.plan_duration ? `${item.plan_duration} Year${item.plan_duration > "" ? 's' : ''}` : "0 Year"}

                          

                          </h6>
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700">
                       
                              {item?.total_invested_amount ?? ""}
                          

                          </h6>
                        </div>
                      </td>
                      <td className="align-middle status fs-9 pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700">
                              {item?.total_investment_plans ?? ""}
                          
                          </h6>
                        </div>
                      </td>
                      <td className="align-middle status fs-9 pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700">
                              {item?.total_returns ?? ""}
                          
                          </h6>
                        </div>
                      </td>
                      {/* <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input toggle_btn"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${item.id}`}
                            checked={item.status === 1}
                            onChange={() => handleStatusChange(item.id, item.status)}
                            disabled={loading}
                          />
                        </div>
                      </td> */}


                      {/* <td className="align-middle priority pe-4">
                        <div className="d-flex gap-1  action_button">
                          <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={
                              <Tooltip id={`Update`}>Add Business</Tooltip>
                            }
                          >
                            <button
                              onClick={() => handleAddbusiness(item.id, item.name)}
                              className="acive_btn bg-primary"
                              type="button"
                              style={{ "--i": "#07bc0c" }}
                            >
                              Add B
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={
                              <Tooltip id={`Update`}>Add Customer</Tooltip>
                            }
                          >
                            <button
                              onClick={() => HandleAddadminscustomer(item.id,item.name)}
                              className="acive_btn "
                              type="button"
                              style={{ "--i": "#07bc0c" }}
                            >
                              Add C
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td> */}
                      {/* <td className="align-middle priority pe-4">
                        <div className="d-flex gap-1 action_button">
                         
                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              onClick={() => handleClickCompanyId(item.cust_id)}
                              style={{ "--i": "#27bcfd" }}
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="GenerateBill"
                            placement="top"
                            overlay={<Tooltip id={`GenerateBill`}>Generate Investment Bill</Tooltip>}
                          >
                            <button
                              style={{ "--i": "red" }}
                              onClick={() => GenerateBill(item.cust_id)}
                            >
                              {" "}
                              PDF
                            </button>
                        
                          </OverlayTrigger>
                        </div>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>
      </div>


    </>
  );
}
